import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import CVRequestDetailsSlider from './CVRequestDetailsSlider';
import CVAddRequestSlider from './CVAddRequestSlider';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import { Select, message } from 'antd';
import { Button } from "react-bootstrap";
import { TbFileReport } from "react-icons/tb";
import Moment from 'moment';

export default function CVRequestsTab(props) {
    const [state, setState] = useState({
        selectedContractorId: '',
        requests: [],
        dataLoaded: false,
        showRequestDetailsSlider: false,
        showAddRequestSlider: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        billingStatus: '',
        agentsList: [],
        formFields: {},
        selectedRequestId: null
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { SearchBar, ClearSearchButton } = Search;

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            //console.log(value);
            //console.log(name);
            //console.log(type);

            //if (typeof (getValues().selectedContractorId) !== 'undefined') {
            //    loadContratorRequests();
            //}
        });

        return () => { subscription.unsubscribe(); };

    }, []);

    useEffect(() => {
        var alive = true;

        if (alive && props.selectedContractorId !== null) {
            LoadContratorRequests();
        }
    }, [props]);

    function LoadContratorRequests() { 
        Axios.get(`/api/GetContractorRequests`, {
            params: {
                contractorID: props.selectedContractorId
            }
        }).then(response => {
            let requestsList = response.data;

            console.log(requestsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
                showRequestDetailsSlider: false,
                showAddRequestSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function editFormatter(cell, row) {
        return (
            <i id="editRequest" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    function hrsSummaryFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <TbFileReport id="hrsSummary" className="fa-2x hover" />
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    function detailsFormatter(cell, row) {
        return (
            <div>{row.mrRequest.projectName}<br />Type of Hours: {row.mrRequest.typeOfHours}</div>
        );
    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.mrRequest.requestDate)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    const columns = [{
        dataField: 'mrRequest.requestID',
        text: 'requestID',
        hidden: true
    }, {
        dataField: 'mrRequest.requestDate',
        text: 'Request Date',
        formatter: dateFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.projectName',
        text: 'Project Name',
        sort: true
    }, {
        dataField: 'mrRequest.typeOfHours',
        text: 'Type of Hours',
        sort: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: 'Request Details',
        formatter: editFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }, {
        dataField: 'dum2',
        isDummyField: true,
        text: 'Hours Summary',
        formatter: hrsSummaryFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editRequest') {
                LoadRequestDetails(row.mrRequest.requestID, rowIndex)
            }
            else if (e.target.tagName === 'svg' && e.target.id === 'hrsSummary') {
                DownloadHoursSummaryReport(row.mrRequest.requestID, row.mrRequest.projectName, row.mrRequest.typeOfHours);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            //toast.info(e.target.tagName);
            LoadRequestDetails(row.mrRequest.requestID, rowIndex);
        }
    };

    function LoadRequestDetails(requestID, rowIndex) {
        Axios.get(`/api/GetRequestById`, {
            params: {
                requestID: requestID
            }
        }).then(response => {
            setState({
                ...state,
                selectedRequestIdx: rowIndex,
                selectedRequest: response.data.mrRequest,
                selectedRequestId: requestID,
                showRequestDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' },
        hideSelectColumn: true
    };

    function handleAddRequest() {
        setState({
            ...state,
            showAddRequestSlider: true,
            buttonLabel: 'Save New Request',
            modalHeader: 'Add New Request',
            requestEditMode: 'Add',
            formFields: {},
            selectedRequestId: null,
            selectedContactName: ''
        });
    }

    function DownloadHoursSummaryReport(requestID, projectName, typeOfHours) {
        let myFileName = "";

        myFileName = "Hours_Summary_For_Request_Report";

        const key = "loading";

        let postdata = {
            requestID: requestID,
            projectName: projectName,
            typeOfHours: typeOfHours
        }

        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadHoursSummaryForRequestReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    async function updateTableDisplay() {
        LoadContratorRequests();
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Contractor Market Recovery Requests</span></Card.Header>
                <Card.Body>
                    <div className="panel-hdr noTitle">
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddRequest}>
                            Submit New Request
                        </button>
                    </div>
                    <div className="row color-dark-blue">
                        Specify Selection Criteria
                    </div>
                    <div className="row">
                        &nbsp;
                    </div>
                    <div>
                        {state.dataLoaded &&
                            <Fragment>
                                {state.requests.length === 0 ?
                                    <div>No Market Recovery Requests Found</div>
                                    :
                                    <div className="fullTable">
                                        <ToolkitProvider
                                            keyField='mrRequest.requestID'
                                            data={state.requests}
                                            columns={columns}
                                            search
                                        >
                                            {props => (
                                                <div>
                                                    <SearchBar {...props.searchProps} style={{ width: "600px", height: "40px" }} placeholder='Search by Project Name, Type of Hours or Status' /><br />
                                                    <button onClick={() => props.searchProps.onClear()}>Clear Search Criteria</button>
                                                    <hr style={{ border: "1px solid" }} />
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        filterPosition="bottom"
                                                        noDataIndication="There is no solution"
                                                        striped
                                                        hover
                                                        condensed
                                                        pagination={paginationFactory(options)}
                                                        rowEvents={rowEvents}
                                                        selectRow={selectRow}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>
                    {state.showRequestDetailsSlider &&
                        <CVRequestDetailsSlider showPane={state.showRequestDetailsSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }
                    {state.showAddRequestSlider &&
                        <CVAddRequestSlider showPane={state.showAddRequestSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }
                </Card.Body>
            </Card>
        </div>
    );
}