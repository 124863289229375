import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Input, AutoComplete, Skeleton } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import ContractorDetailsSlider from './ContractorDetailsSlider';
import ContractorContactsSlider from './ContractorContactsSlider';

import produce from "immer"

export default function ContractorManager() {
    const { SearchBar } = Search;

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        contractors: [],
        selectedContractor: null,
        dataLoaded: false,
        formFields: {},
        noContractors: true,
        selectedContractorStatus: 'All',
        selectedIdx: null,
        showContractorDetailsSlider: false,
        showContractorContactsSlider: false,
        contractorID: null,
        contractorName: ''
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    //useEffect(() => {
    //    GetContractors();
    //}, []);

    useEffect(() => {
        if (location.state !== null && location.state?.pageNumber !== null) {
            console.log(location.state.selectedContractor);

            setTableControl({
                ...tableControl,
                page: location.state.pageNumber,
                sizePerPage: location.state.sizePerPage
            });
        }

        GetContractors();
    }, [state.selectedContractorStatus]);

    function GetContractors() {
        Axios.get(`/api/GetContractors`, {
            params: {
                contractorStatus: state.selectedContractorStatus
            }
        }).then(response => {
            let contractorsList = response.data;
            let mySelectedContractor = null
            
            if (location.state !== null && location.state?.selectedContractor !== undefined) {
                console.log(location.state?.selectedContractor);
                mySelectedContractor = location.state.selectedContractor;
            }

            setState({
                ...state,
                contractors: contractorsList,
                selectedContractor: mySelectedContractor,
                dataLoaded: true,
                results: contractorsList.slice(0, 10),
                showContractorDetailsSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'openContractor') {
                navigate("/contractor-dashboard", { state: { selectedContractor: row, selectedContractorId: row.contractors_ID, selectedContractorName: row.name, pageNumber: tableControl.page, sizePerPage: tableControl.sizePerPage, contractors: state.contractors } });
            }
            else if (e.target.tagName === 'I' && e.target.id === 'deleteContractor') {
                handleDelete(row);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            navigate("/contractor-dashboard", { state: { selectedContractor: row, selectedContractorId: row.contractors_ID, selectedContractorName: row.name, pageNumber: tableControl.page, accounts: state.contractors } });
        }
    };

    function handleContractorStatusChange(e) {
        setState({
            ...state,
            selectedContractorStatus: e.target.value
        });
    }

    function handleDelete(contractor) {
        confirm({
            title: "You are about to permanently delete this Contractor",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
                contractor.contractorStatus = state.selectedContractorStatus;

                Axios.post(`/api/DeleteContractor`, contractor
                ).then(response => {
                    if (response.data) {
                        toast.success("Contractor has been deleted");

                        setState({
                            ...state,
                            contractors: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateContractors()
    {
        GetContractors();

        //await setState({ ...state, dataLoaded: false });

        //if (idxToDirectTo !== null && idxToDirectTo !== 0) {
        //    const page = Math.ceil(idxToDirectTo / tableControl.sizePerPage);

        //    console.log(page);

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}
    }

    function handleAddCustomer()
    {
        console.log('here');

        setState({
            ...state,
            selectedContractorId: null,
            showContractorDetailsSlider: true,
            selectedIdx: null,
            loading: false
        });
    }

    function handleTableChange(type, { page, sizePerPage }) {

    }

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        //await setState({ ...state, dataLoaded: false });

        //console.log(requests[idxToDirectTo]);

        //if (idxToDirectTo !== null) {
        //    const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}

        //setState({
        //    ...state,
        //    requests,
        //    selectedRequest: requests[idxToDirectTo],
        //    dataLoaded: true,
        //    showRequestDetailsSlider: false
        //});
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    function deleteFormatter(cell, row) {
        return (
            <i id="deleteContractor" className="far fa-trash-alt fa-1x ml-3 hover text-danger"></i>
        );
    }

    function openFormatter(cell, row) {
        return (
            <i id="openContractor" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    const columns = [{
        dataField: 'contractors_ID',
        text: 'Contractors_ID',
        hidden: true
    }, {
        dataField: 'name',
        text: 'Name',
        sort: true
    }, {
        dataField: 'city',
        text: 'City',
        sort: true
    }, {
        dataField: 'state',
        text: 'State',
        sort: true
    }, {
        dataField: 'status',
        text: 'Status',
        sort: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: 'Manage Contractor',
        formatter: openFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '150px' }
    }, {
        dataField: 'dum2',
        isDummyField: true,
        text: 'Delete Contractor',
        formatter: deleteFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '150px' }
    }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' },
        hideSelectColumn: true,
        selected: [state.selectedContractor?.contractors_ID]
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            MANAGE CONTRACTORS
                        </h2>

                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>
                            Add Contractor
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <div className="row">
                            <div>
                                <label className="form-label">Contractor Status:</label>&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="All" className="mt-1" checked={state.selectedContractorStatus === "All"} />&nbsp;<label className="form-label">All</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="A" className="mt-1" checked={state.selectedContractorStatus === "A"} />&nbsp;<label className="form-label">Active</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="I" className="mt-1" checked={state.selectedContractorStatus === "I"} />&nbsp;<label className="form-label">Inactive</label>&nbsp;&nbsp;&nbsp;
                                <input type="radio" name="contractorStatus" onChange={handleContractorStatusChange} value="N" className="mt-1" checked={state.selectedContractorStatus === "N"} />&nbsp;<label className="form-label">New</label>
                            </div>
                        </div>
                        <div className="frame-wrap">
                            {state.loading ?
                                <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                :
                                <div>
                                    {state.contractors.length == 0 ?
                                        <div>No Contractors Found</div>
                                        :
                                        <Fragment>
                                        
                                            <div className="fullTable">
                                                {state.dataLoaded &&
                                                    <ToolkitProvider
                                                        keyField='contractors_ID'
                                                        data={state.contractors}
                                                        columns={columns}
                                                        columnToggle
                                                        search
                                                        remote
                                                        onTableChange={handleTableChange}
                                                    >
                                                        {
                                                            props => (
                                                                <div>
                                                                    <SearchBar {...props.searchProps} />
                                                                    <BootstrapTable
                                                                        pagination={paginationFactory(options)}
                                                                        onTableChange={handleTableChange}
                                                                        {...props.baseProps}
                                                                        rowEvents={rowEvents}
                                                                        selectRow={selectRow}
                                                                        hover condensed />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            }
                        </div>
                        {state.showContractorDetailsSlider &&
                            <ContractorDetailsSlider selectedContractorId={state.selectedContractorId} showPane={state.showContractorDetailsSlider} contractors={state.contractors} updateContractors={updateContractors} hidePane={() => setState({ ...state, showContractorDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}