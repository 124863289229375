import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';

export default function UserDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        users: [],
        selectedUserId: '',
        selectedIdx: '',
        formFields: {},
        btnSubmitClicked: false,
        buttonLabel: '',
        states: statesList
    });

    useEffect(() => {
        setState({
            ...state,
            states: statesList
        });
    }, []);

    useEffect(() => {
        if (props.selectedUserId !== null) {
            Axios.get(`/api/GetUserById`, {
                params: {
                    userid: props.selectedUserId
                }
            }).then(response => {
                setState({
                    ...state,
                    formFields: response.data,
                    showPane: props.showPane,
                    users: props.users,
                    selectedUserId: props.selectedUserId,
                    selectedIdx: props.selectedIdx,
                    buttonLabel: 'Update User Info'
                });
            }).catch(error => {
                console.log(error);
            });
        }
        else {
            let formFields = {
                status: 'A',
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                userType: 'Select Type'
            }

            setState({
                ...state,
                showPane: props.showPane,
                users: props.users,
                selectedUserId: '',
                selectedIdx: '',
                formFields
            });
        }
    }, [props]);

    function hidePane() {
        //console.log('ExhibitorLeadsSlider hidePane invoked');
        setState({
            ...state,
            showPane: false
        });

        //props.hidePane();
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(name);
        console.log(value);

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleStatusChange(e) {
        setState({
            ...state,
            formFields: { ...state.formFields, status: e.target.value }
        });
    }

    function screenDataOK() {
        let message = '';

        console.log(state.formFields);

        if (state.formFields.firstName === '') {
            message += '&bull;&nbsp;First Name</br>';
        }
        if (state.formFields.lastName === '') {
            message += '&bull;&nbsp;Last Name</br>';
        }
        if (state.formFields.email === '') {
            message += '&bull;&nbsp;Email</br>';
        }
        if (state.formFields.password === '') {
            message += '&bull;&nbsp;Password</br>';
        }
        if (state.formFields.userType === 'Select Type') {
            message += '&bull;&nbsp;User Type</br>';
        }

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return false;
        }
        else {
            return true;
        }
    }

    function handleSubmit() {
        console.log(state.formFields);

        if (screenDataOK() === false) {
            return;
        }

        let formFields = state.formFields;

        if (state.selectedUserId) {
            Axios.post(`/api/UpdateUser`, formFields
            ).then(response => {
                toast.success("User Information Successfully Updated");

                props.updateUsers();

            }).catch(error => {
                toast.error("Problem Encountered Updating User");

                console.log(error);
            });
        }
        else {
            formFields.users_ID = 0;

            Axios.post(`/api/AddNewUser`, formFields
            ).then(response => {
                //now update the parent list
                if (response.data) {
                    toast.success("New User Successfully Added");

                    //for graceful, animated close
                    setTimeout(() => props.updateUsers(), 1000);

                    setState({
                        ...state,
                        showPane: false
                    });
                }
            }).catch(error => {
                toast.error("Problem Encountered Adding New User");

                console.log(error);
            });
        }
    }

    return (
        <SlidingPane
            className='some-custom-class w-75'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={state.selectedUserId ? 'Edit Existing User' : 'Add New User'}
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <Card className="local777Card">
                                <Card.Header><span>User Information (*Indicates required field)</span></Card.Header>
                                <Card.Body>
                                    <form>
                                        <div className="row">
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">* First Name</label>
                                                <input type="text" name="firstName" value={state.formFields.firstName || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">* Last Name</label>
                                                <input type="text" name="lastName" value={state.formFields.lastName || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Title</label>
                                                <input type="text" name="title" value={state.formFields.title || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">* Email</label>
                                                <input type="text" name="email" value={state.formFields.email || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">* Password</label>
                                                <input type="text" name="password" value={state.formFields.password || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-6"></div>

                                            <div className="form-group col-lg-2">
                                                <label className="form-label">* User Type</label>
                                                <select name="userType" className="form-control-custom" value={state.formFields.userType} onChange={handleInputChange}>
                                                    <option>Select Type</option>
                                                    <option>Administrator</option>
                                                    <option>Agent</option>
                                                    <option>SuperAgent</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}