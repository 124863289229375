import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { useForm, Controller } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import statesList from '../Helpers/StatesList';
import { toast, confirm } from '@rickylandino/react-messages';

export default function MROldRequest(props) {
    const { Option } = Select;

    const [state, setState] = useState({
        allContractors: [],
        allJobs: [],
        selectedContractorId: '',
        contractorContacts: [],
        contactsDisabled: true,
        contactsPlaceHolder: 'Select Contractor To Display Contacts',
        selectedContactId: null,
        formFields: {},
        states: statesList,
        contractorsLoaded: false
    });

    const { register, getValues, setValue, control, watch } = useForm();


    function onChange(value) {
        //console.log(`selected contractor ${value}`);

        setValue('formFields.contractors_ID', value)

        if (value !== '') {
            getContractorContacts(value);
        }
        else {
            setState({
                ...state,
                contactsPlaceHolder: 'Select Contractor To Display Contacts',
                contractorContacts: [],
                selectedContactId: null,
                contactsDisabled: true
            });
        }
    }

    function onSearch(val) {
        //console.log('search:', val);
    }

    function onContactChange(value) {
        //console.log(`selected contact ${value}`);

        setValue('formFields.contacts_ID', value)
        setState({
            ...state,
            selectedContactId: value
        });
    }

    useEffect(() => {
        getAllContractors();

        if (state.contractorsLoaded == true) {
            getAllJobs();
        }

        //getAllJobs();

        setValue('formFields', {});

        const subscription = watch((value, { name, type }) => {
            //console.log(value);
            //console.log(name);
            //console.log(type);
        });

        return () => subscription.unsubscribe();

    }, [props]);

    function getAllContractors() {
        Axios.get(`/api/GetAllContractors`, {
            params: {

            }
        }).then(response => {
            //console.log(response.data);
            setState({
                ...state,
                allContractors: response.data,
                contractorsLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function getAllJobs() {
        Axios.get(`/api/GetAllJobs`, {
            params: {

            }
        }).then(response => {
            //console.log(response.data);
            setState({
                ...state,
                allJobs: response.data
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function getContractorContacts(selectedContractorId) {
        Axios.get(`/api/GetContractorContacts`, {
            params: {
                contractorID: selectedContractorId
            }
        }).then(response => {
            let contactsList = response.data;

            var contactsDisabled;
            var contactsPlaceHolder;

            if (contactsList.length > 0) {
                contactsDisabled = false;
                contactsPlaceHolder = 'Select Contact';
            }
            else {
                contactsDisabled = true;
                contactsPlaceHolder = 'No Contacts Found';
            }

            setState({
                ...state,
                contractorContacts: contactsList,
                selectedContractorId: selectedContractorId,
                contactsDisabled,
                contactsPlaceHolder,
                selectedContactId: null
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleDateChange(name, date) {
        //let formFields = { ...this.state.formFields, [name]: date }

        //let disableSaveBtn = this.state.disableSaveBtn;

        //if (formFields.examDateTime &&
        //    formFields.examTypeID &&
        //    formFields.performingTechUserID &&
        //    formFields.insuranceID &&
        //    formFields.providerID) {
        //    disableSaveBtn = false;
        //}

        //this.setState({ formFields, disableSaveBtn }, () => {
        //    if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
        //        this.isDirty = true;  // dirty
        //        Globals.isDirtyPage = true;
        //    }
        //});
    }

    function handleAddRequest() {
        //var myMissingFields;

        //console.log(getValues().formFields.projectName);

        //myMissingFields = "";

        //if (!/\D/.test(getValues().formFields.projectame) === false || getValues().formFields.projectame == '') {
        //    myMissingFields = "Project Name<br />";
        //}

        //if (myMissingFields !== "") {
        //    toast.error(myMissingFields);
        //    return;
        //}

        ////if (getValues().formFields.projectame == '') {
        ////    toast.error("D2P App show not added - invalid show code specified");
        ////    return;
        ////}
        //console.log(getValues().formFields);

        setValue('formFields.jobsiteCityState', getValues().formFields.city + ' ' + getValues().formFields.state + ', ' + getValues().formFields.zip);
        console.log(getValues().formFields.jobsiteCityState)

        let message = "<strong>Mandatory fields missing or invalid data specified</strong><br />";

        if (getValues().formFields.bidDate === '' || getValues().formFields.bidDate === null || typeof getValues().formFields.bidDate === 'undefined') {
            message += '&bull;&nbsp;Invalid Bid Date specified</br>';
        }
        if (typeof getValues().formFields.contractors_ID === 'undefined' || getValues().formFields.contractors_ID == '') {
            message += '&bull;&nbsp;Contractor is required</br>';
        }
        if (typeof getValues().formFields.contacts_ID === 'undefined' || getValues().formFields.contacts_ID == '') {
            message += '&bull;&nbsp;Contact is required</br>';
        }
        if (typeof getValues().formFields.projectName === 'undefined' || getValues().formFields.projectName == '') {
            message += '&bull;&nbsp;Project Name is required</br>';
        }
        if (typeof getValues().formFields.city === 'undefined' || getValues().formFields.city == '' || typeof getValues().formFields.state === 'undefined' || getValues().formFields.state == '' || typeof getValues().formFields.zip === 'undefined' || getValues().formFields.zip == '') {
            message += '&bull;&nbsp;Jobsite City, State & Zip is required</br>';
        }
        if (typeof getValues().formFields.requestedHours === 'undefined' || getValues().formFields.requestedHours == '') {
            message += '&bull;&nbsp;Requested Hours is required</br>';
        }
        if (typeof getValues().formFields.typeOfHours === 'undefined' || getValues().formFields.typeOfHours == '') {
            message += '&bull;&nbsp;Type of Hours is required</br>';
        }
        if (typeof getValues().formFields.workDescription === 'undefined' || getValues().formFields.workDescription == '') {
            message += '&bull;&nbsp;Work Description is required</br>';
        }
        if (typeof getValues().formFields.awardingParty === 'undefined' || getValues().formFields.awardingParty == '') {
            message += '&bull;&nbsp;Awarding Party is required</br>';
        }
        if (typeof getValues().formFields.unionBidder1 === 'undefined' || getValues().formFields.unionBidder1 == '') {
            message += '&bull;&nbsp;Union Bidder (mimimum of 1 is required)</br>';
        }
        if (typeof getValues().formFields.nonUnionBidder1 === 'undefined' || getValues().formFields.nonUnionBidder1 == '') {
            message += '&bull;&nbsp;Non Union Bidder (mimimum of 1 is required)</br>';
        }

        if (message != '') {
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }
        else {
            
            //Save Old Request
        }
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Add Old Market Recovery Request (* indicates required field)</span></Card.Header>
                <Card.Body>
                    <form>
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <label className="form-label">Bid Date <span className="color-pink">*</span></label>
                                <Controller
                                    name="formFields.bidDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.bidDate ? Moment(getValues().formFields.bidDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.bidDate ? Moment(getValues().formFields.bidDate).toDate() : null}
                                            onChange={date => setValue('formFields.bidDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-10">

                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Contractor <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contractors_ID")}
                                    size="large" placeholder="Select Contractor"
                                    style={{ width: 400 }}
                                    showSearch
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    onChange={onChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                >
                                    {/*                    <Option value="jack">Jack</Option>*/}
                                    <option value='' />
                                    {state.allContractors.map(c => <Option value={c.contractors_ID}>{c.name}</Option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Contact <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contacts_ID")}
                                    disabled={state.contactsDisabled}
                                    size="large" placeholder={state.contactsPlaceHolder}
                                    style={{ width: 400 }}
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={onContactChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={state.selectedContactId}
                                >
                                    {state.contractorContacts.map((con, idx) => <option key={idx} value={con.contacts_ID}>{con.contactName}</option>)}
                                </Select>

                                {/*<select className="form-control-custom" disabled={state.contactsDisabled}>*/}
                                {/*    <option value='' />*/}
                                {/*    {state.contractorContacts.map((con, idx) => <option key={idx} value={con.Contacts_ID}>{con.contactName}</option>)}*/}
                                {/*</select>*/}
                            </div>
                            <div className="form-group col-lg-4">

                            </div>

                            <div className="form-group col-lg-12">
                                &nbsp;
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label color-dark-blue">JOB INFORMATION</label>
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Project Name</label>
                                <input type="text" {...register("formFields.projectName")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-8">
                                <label className="form-label">Project Details</label>
                                <input type="text" {...register("formFields.projectDetails")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Jobsite Address</label>
                                <input type="text" {...register("formFields.jobsiteAddress")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">City</label>
                                <input type="text" {...register("formFields.city")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" {...register("formFields.state")}>
                                    <option></option>
                                    {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Zip</label>
                                <input type="text" {...register("formFields.zip")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-2">
                                <label className="form-label">Requested Hours</label>
                                <input type="text" {...register("formFields.requestedHours")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Type of Hours</label>
                                <select className="form-control-custom" {...register("formFields.typeOfHours")}>
                                    <option>Select</option>
                                    <option>Plumbing Forman</option>
                                    <option>Plumbing Off Site Fab</option>
                                    <option>HVAC Forman</option>
                                    <option>HVAC Off Site Fab</option>
                                    <option>Plumbing & HVAC Forman</option>
                                    <option>Plumbing & HVAC Fabrication</option>
                                    <option>Plumbing</option>
                                    <option>HVAC</option>
                                    <option>Plumbing & HVAC</option>
                                    <option>Drawing</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Awarding Party</label>
                                <input type="text" {...register("formFields.awardingParty")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Contact Person</label>
                                <input type="text" {...register("formFields.jobsiteContactPerson")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Contact Phone #</label>
                                <input type="text" {...register("formFields.jobsiteContactPhone")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label">Description</label>
                                <textarea name="description" rows="4" cols="50" {...register("formFields.workDescription")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                &nbsp;
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label color-dark-blue">UNION BIDDERS</label>
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label color-dark-blue">NON UNION BIDDERS</label>
                            </div>

                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.unionBidder1")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.nonUnionBidder1")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.unionBidder2")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.nonUnionBidder2")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.unionBidder3")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.nonUnionBidder3")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                &nbsp;
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label color-dark-blue">HOURS GRANTED</label>
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">Hours Granted</label>
                                <input type="text" {...register("formFields.grantedHours")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">$/Hour</label>
                                <input type="text" {...register("formFields.dollarsPerHour")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Granted By</label>
                                <input type="text" {...register("formFields.grantedBy")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Assign To Job</label>
                                <Select
                                    {...register("formFields.jobs_ID")}
                                    size="large" placeholder="Select Job"
                                    style={{ width: 400 }}
                                    showSearch
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    onChange={onChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                >
                                    <option value='' />
                                    {state.allJobs.map(c => <Option value={c.jobs_ID}>{c.jobName}</Option>)}
                                </Select>
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">Award Date</label>
                                <Controller
                                    name="formFields.awardDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.awardDate ? Moment(getValues().formFields.awardDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.awardDate ? Moment(getValues().formFields.awardDate).toDate() : null}
                                            onChange={date => setValue('formFields.awardDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Expected Start Date</label>
                                <Controller
                                    name="formFields.expectedStartDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.expectedStartDate ? Moment(getValues().formFields.expectedStartDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.expectedStartDate ? Moment(getValues().formFields.expectedStartDate).toDate() : null}
                                            onChange={date => setValue('formFields.expectedStartDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Anticipated Completion Date</label>
                                <Controller
                                    name="formFields.anticipatedCompletionDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.anticipatedCompletionDate ? Moment(getValues().formFields.anticipatedCompletionDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.anticipatedCompletionDate ? Moment(getValues().formFields.anticipatedCompletionDate).toDate() : null}
                                            onChange={date => setValue('formFields.anticipatedCompletionDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit margin-left-15 float-left" onClick={handleAddRequest}>Add Request</button>&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </Card.Body>
            </Card>
            
        </div>
    );
}