import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { Divider, List, Typography } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Button, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import ReactTooltip from "react-tooltip";

export default function ReassignToJobSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const { SearchBar } = Search;

    const [state, setState] = useState({
        showPane: props.showPane,
        formFields: {},
        dataLoaded: false,
        matchedJobs: {},
        matchType: 'possiblematches'
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    useEffect(() => {
        //setState({
        //    ...state,
        //    states: statesList,
        //    showPane: true
        //});
    }, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedRequestId !== null && props.selectedRequestId !== "") {
                Axios.get(`/api/GetCities`, {
                    params: {
                        
                    }
                }).then(response => {
                    setValue("citiesList", response.data);

                    setValue("projectName", props.projectName);
                    setValue("projectDetails", props.projectDetails);
                    setValue("jobsiteAddress", props.jobsiteAddress);

                    let parsedAddress = parseAddress(props.jobsiteCityState);

                    setValue("jobsiteCity", parsedAddress.city);
                    setValue("jobsiteState", parsedAddress.state);
                    setValue("jobsiteZip", parsedAddress.zip);

                    //console.log(getValues().jobsiteCity);
                    //console.log(getValues().jobsiteState);
                    //console.log(getValues().jobsiteZip);

                    setValue("selectedJobId", props.selectedJobId);

                    GetMatchingJobs(getValues().projectName.charAt(0), parsedAddress.city, parsedAddress.state, 'possiblematches');

                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [props]);

    function parseAddress(address) {
        // Make sure the address is a string.
        if (typeof address !== "string") throw "Address is not a string.";

        // Trim the address.
        address = address.trim();

        // Make an object to contain the data.
        var returned = {};

        // Find the comma.
        var comma = address.indexOf(',');

        // Pull out the city.
        returned.city = address.slice(0, comma);

        // Get everything after the city.
        var after = address.substring(comma + 2); // The string after the comma, +2 so that we skip the comma and the space.

        // Find the space.
        var space = after.lastIndexOf(' ');

        // Pull out the state.
        returned.state = after.slice(0, space);

        // Pull out the zip code.
        returned.zip = after.substring(space + 1);

        // Return the data.
        return returned;
    }

    function handleTableChange(type, { page, sizePerPage }) {

    }

    function GetMatchingJobs(jobNameFirstLetter, jobCity, jobState, matchType) {
        Axios.get(`/api/GetMatchingJobs`, {
            params: {
                jobNameFirstLetter: jobNameFirstLetter,
                jobCity: jobCity,
                jobState: jobState
            }
        }).then(response => {
            setValue("matchingJobs", response.data);

            setState({
                ...state,
                dataLoaded: true,
                matchType: matchType,
                showPane: true
            });

            //console.log(getValues().matchingJobs);

        }).catch(error => {
            console.log(error);
        });
    }

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleSubmit() {
        
    }

    function onAddAndAssignAsNewJob() {
        let errString = '';
        let jobVisibility = "M";

        if (getValues().projectName === '') {
            errString += "&#8226;&nbsp;Job Name<br />";
        }
        if (getValues().jobsiteAddress === '') {
            errString += "&#8226;&nbsp;Job Site Address<br />";
        }
        if (getValues().jobsiteCity === '') {
            errString += "&#8226;&nbsp;Job Site City<br />";
        }
        if (getValues().jobsiteState === '') {
            errString += "&#8226;&nbsp;Job Site State<br />";
        }
        if (getValues().jobsiteZip === '') {
            errString += "&#8226;&nbsp;Job Site Zip<br />";
        }

        if (errString !== '') {
            errString = "<strong>Job cannot be added. Mandatory fields are missing</strong><br />" + errString;
            toast.error(<span style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: errString }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }

        if (state.formFields.visibility === true) {
            jobVisibility = "B";
        }

        let postdata = {
            JobName: getValues().projectName,
            JobDetails: getValues().projectDetails,
            Address: getValues().jobsiteAddress,
            City: getValues().jobsiteCity,
            State: getValues().jobsiteState,
            Zip: getValues().jobsiteZip,
            Visibility: jobVisibility
        }

        Axios.post(`/api/AddNewJob`, postdata
        ).then(response => {
            if (response.data) {
                console.log(response.data.newID);

                setValue('selectedJobId', response.data.newID);

                //now update the parent list, with graceful, animated close
                setTimeout(() => props.updateAssignedJob(response.data.newID), 1000);

                setState({
                    ...state,
                    showPane: false
                });
            }
        }).catch(error => {
            toast.error("Problem Encountered Adding New Job");

            console.log(error);
        });
    }

    function onAssignToSelectedJob() {
        //now update the parent list, with graceful, animated close
        setTimeout(() => props.updateAssignedJob(getValues().selectedJobId), 1000);

        setState({
            ...state,
            showPane: false
        });
    }

    function onMatchTypeChanged(e) {
        setState({
            ...state,
            matchType: e.target.value
        });

        if (e.target.value === 'all') {
            GetMatchingJobs('', '', '', 'all');
        }
        else
        {
            //let parsedAddress = parseAddress(getValues().jobsiteCityState);

            //setValue("jobsiteCity", getValues().jobsiteCity);
            //setValue("jobsiteState", getValues().jobsiteState);
            //setValue("jobsiteZip", parsedAddress.zip);

            GetMatchingJobs(getValues().projectName.charAt(0), getValues().jobsiteCity, getValues().jobsiteState, 'possiblematches');
        }
    }

    function infoFormatter(cell, row) {
        //dangerouslySetInnerHTML={{ __html: errString }}

        let myToolTipText = "<div style={{ lineHeight: '34%' }}>" + row.jobName + "<br />" + row.address + "<br />" + row.city + ", " + row.state + " " + row.zip + "<br /></div>";
        return (
            <span data-tip={myToolTipText} data-html={true}><i id="editRequest" className="fas fa-info fa-2x ml-3 text-center hover"></i></span>
        );
    }

    //const columns = [{
    //    dataField: 'jobs_ID',
    //    text: 'jobs_ID',
    //    hidden: true
    //}, {
    //    dataField: 'jobName',
    //    text: 'Job Name',
    //    sort: true
    //}, {
    //    dataField: 'dum1',
    //    isDummyField: true,
    //    text: 'Job  Details',
    //    formatter: infoFormatter,
    //    style: { textAlign: 'center' },
    //    headerStyle: { width: '130px' },
    //}];

    const columns = [{
        dataField: 'jobs_ID',
        text: 'jobs_ID',
        hidden: true
    }, {
        dataField: 'jobName',
        text: 'Job Name',
        sort: true
    }, {
        dataField: 'address',
        text: 'Address',
        sort: true
    }, {
        dataField: 'city',
        text: 'City',
        headerStyle: { width: '220px' },
        sort: true
    }, {
        dataField: 'state',
        text: 'State',
        headerStyle: { width: '60px' },
        sort: true
    }, {
        dataField: 'zip',
        text: 'Zip Code',
        headerStyle: { width: '130px' },
        sort: true
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            setValue('selectedJobId', row.jobs_ID);
        },
        onDoubleClick: (e, row, rowIndex) => {
            setValue('selectedJobId', row.jobs_ID);
        }
    };

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: 'lightgrey',
        hideSelectColumn: true,
        selected: [getValues().matchingJobs?.jobs_ID || null]
    };

    function handleCityStateInputChange(event) {
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        console.log(name);
        console.log(value);

        setValue(name, value);

        setState({
            ...state
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Job Details'
            onRequestClose={hidePane}
            width={'65%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <Card className="local777Card">
                                <Card.Header><span>Add New Job or Match to Existing Job</span></Card.Header>
                                <Card.Body>
                                    <form>
                                        <div className="row">
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Job Name</label>
                                                <input type="text" className="form-control-custom" {...register('projectName')} />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Job Details</label>
                                                <input type="text" className="form-control-custom" {...register('projectDetails')} />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Project Profiling Visibility</label><br />
                                                <input type="checkbox" id="visibility" name="visibility" value={state.formFields.visibility || ''} onChange={handleInputChange} />
                                                <label className="form-label" htmlFor="visibility" />&nbsp;Make Job Available In Project Profiling
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Address</label>
                                                <input type="text" className="form-control-custom" {...register('jobsiteAddress')} />
                                            </div>
                                            <div className="form-group col-lg-3">
                                                <label className="form-label">City</label>
                                            <select className="form-control-custom" {...register('jobsiteCity')} value={getValues().jobsiteCity} onChange={handleCityStateInputChange}>
                                                    <option value="">Select City</option>
                                                    {getValues().citiesList?.length > 0 && getValues().citiesList?.map((city, idx) => <option index={idx} key={city.cities_ID} value={city.city}>{city.city}</option>)}
                                                </select>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">State</label>
                                            <select className="form-control-custom" {...register('jobsiteState')} value={getValues().jobsiteState} onChange={handleCityStateInputChange}>
                                                <option value="">Select State</option>
                                                <option value="CT">CT</option>
                                            </select>
                                            </div>
                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Zip</label>
                                                <input type="text" className="form-control-custom" {...register('jobsiteZip')} />
                                            </div>
                                            

                                            <div className="form-group col-lg-12">
                                                <hr style={{ border: "1px solid" }} />
                                            </div>
                                            <div className="row">
                                                <div onChange={onMatchTypeChanged}>
                                                    <label className="form-label">Match With Existing Job:</label>&nbsp;&nbsp;
                                                    <input type="radio" className="mt-1" name="matchType" value="possiblematches" checked={state.matchType === "possiblematches"} />&nbsp;<label className="form-label">Show Possible Matches</label>&nbsp;&nbsp;&nbsp;
                                                    <input type="radio" className="mt-1" name="matchType" value="all" checked={state.matchType === "all"} />&nbsp;<label className="form-label">Show All</label>&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                            <div className="form-group col-lg-12">
                                                {state.dataLoaded &&
                                                    <Fragment>
                                                        {getValues().matchingJobs?.length === 0 ?
                                                            <div>No Matching Jobs Found</div>
                                                            :
                                                            <div className="fullTable">
                                                                <ToolkitProvider
                                                                    keyField='jobs_ID'
                                                                    data={getValues().matchingJobs}
                                                                    columns={columns}
                                                                    search
                                                                >
                                                                    {
                                                                        props => (
                                                                            <div>
                                                                                <ReactTooltip place="left" type="success" effect="solid" />
                                                                                <SearchBar {...props.searchProps} />
                                                                                <BootstrapTable
                                                                                    pagination={paginationFactory(options)}
                                                                                    {...props.baseProps}
                                                                                    rowEvents={rowEvents}
                                                                                    selectRow={selectRow}
                                                                                    hover condensed />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </ToolkitProvider>
                                                            </div>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>                      
                                    </form>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit btn-sm" onClick={onAddAndAssignAsNewJob}>Add And Assign As New Job</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-submit btn-sm" onClick={onAssignToSelectedJob}>Assign To Selected Job</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}