import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Input, AutoComplete, Skeleton } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Select, Spin, Alert, Empty, message } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import Card from 'react-bootstrap/Card';
import MRRequestDetailsSlider from './MRRequestDetailsSlider';
import TestSlider from './TestSlider';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import mailflag from '../../img/MailFlag.png';
import mailflagred from '../../img/MailFlagRed.png';
import produce from "immer"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { TbFileReport } from "react-icons/tb";

export default function RequestManager() {
    const { SearchBar } = Search;
    const { Option } = Select;
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        requests: [],
        dataLoaded: false,
        showRequestDetailsSlider: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        formFields: {},
        selectedRequestId: null
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [agentsListData, setAgentsListData] = useState([]);

    const [contractorsListData, setContractorsListData] = useState([]);

    const [jobsListData, setJobsListData] = useState([]);

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        //const subscription = watch((value, { name, type }) => {
        //    if (name === 'formFields.requestAgent' || name === 'formFields.requestStatus' || name === 'formFields.contractor' || name === 'formFields.job') {
        //        //console.log('about to call GetFilteredRequests...');
        //        //console.log('search criteria changed...');
        //        //GetFilteredRequests();
        //    }
        //});

        setValue('formFields', {});
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null);
        setValue('formFields.job', null);
        setValue('formFields.archived', 'Active');
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);

        setState({
            ...state
        });

        //console.log('about to initialize...');
        InitializeRequestManager();

        /*return () => { subscription.unsubscribe(); };*/
    }, []);

    useEffect(() => {
        //console.log(state.requests);
    }, [state.requests]);

    function InitializeRequestManager() {
        console.log(getValues().formFields);

        Axios.get(`/api/InitializeRequestManager`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: getValues().formFields.archived,
                fromDate: null,
                toDate: null
            }
        }).then(response => {
            let requestsList = response.data.requests;
            let agentsList = response.data.agents;
            let contractorsList = response.data.contractors;
            let jobsList = response.data.jobs;

            console.log(requestsList);

            setAgentsListData(agentsList);
            setContractorsListData(contractorsList);
            setJobsListData(jobsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetFilteredRequests() {
        Axios.get(`/api/GetFilteredRequests`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: getValues().formFields.archived,
                fromDate: getDateStr(getValues().formFields.fromDate),
                toDate: getDateStr(getValues().formFields.toDate)
            }
        }).then(response => {
            let requestsList = response.data;
            console.log(requestsList);
            //console.log(state);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
                showRequestDetailsSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const getDateStr = (date) => {

        if (date === null) { return ""; }

        let year = date.getFullYear().toString();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        if (month.length == 1) { month = '0' + month; }
        if (day.length == 1) { day = '0' + day; }

        return year + month + day;
    }

    function handleContractorStatusChange(e) {
        setState({
            ...state,
            selectedContractorStatus: e.target.value
        });
    }

    function handleDelete(contractor) {
        confirm({
            title: "You are about to permanently delete this Contractor",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                //this uses the ignore field in the AccountsModel to pass in the selected filterType to be used when returning the new list of Accounts
                contractor.contractorStatus = state.selectedContractorStatus;

                Axios.post(`/api/DeleteContractor`, contractor
                ).then(response => {
                    if (response.data) {
                        toast.success("Contractor has been deleted");

                        setState({
                            ...state,
                            contractors: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function awardedFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Awarded') {
            if (row.mrRequest.dollarsPerHour < 10) {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflag} style={{ height: '15px' }} /></span>
                );
            }
            else {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflagred} style={{ height: '15px' }} /></span>
                );
            }
        }
        else {
            return (
                <span></span>
            );
        }
    }

    function editFormatter(cell, row) {
        return (
            <i id="editRequest" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    function deleteFormatter(cell, row) {
        return (
            <i id="deleteRequest" className="far fa-trash-alt fa-1x ml-3 hover text-danger"></i>
        );
    }

    function detailsFormatter(cell, row) {
        return (
            <div>{row.mrRequest.projectName}<br />Type of Hours: {row.mrRequest.typeOfHours}</div>
        );
    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.mrRequest.requestDate)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    function handleUnarchiveRequest(e, idx) {
        confirm({
            title: "You are about to unarchive this request",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {

                const target = e.target;
                const value = target.value;

                var newList = [...state.requests];
                var itemToUpdate = newList[idx];

                var newMrRequest = { ...itemToUpdate.mrRequest };
                newMrRequest.archived = 0;
                itemToUpdate.mrRequest = newMrRequest;
                newList[idx] = itemToUpdate;

                let postdata = {
                    RequestID: itemToUpdate.mrRequest.requestID,
                    archived: 0
                }

                //set selected request to 'Lost'
                Axios.post(`/api/ToggleRequestArchive`, postdata
                ).then(response => {

                }).catch(error => {
                    toast.error("There was a problem setting requests to 'Lost'");
                    console.log(error);
                });

                setState({
                    ...state,
                    requests: updateObjectInArray(newList, idx, itemToUpdate, 'archived'),
                    dataLoaded: true
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function updateObjectInArray(array, idx, itemToUpdate, propertyChanged) {
        return array.map((item, index) => {
            if (index !== idx) {
                // This isn't the item we care about - keep it as-is
                return item
            }

            // Otherwise, this is the one we want - return an updated value
            return {
                ...itemToUpdate,
                [propertyChanged]: itemToUpdate[propertyChanged]
            }
        })
    }

    function archivedFormatter(cell, row, idx) {
        if (row.mrRequest.archived) {
            return (
                <div>
                    <i id="arch" className="fa fa-check ml-3 hover" /><br />
                    <button id="unarchive" type="button" class="btn btn-link" onClick={(e) => handleUnarchiveRequest(e, idx)}>Unarchive</button>
                </div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    function hrsSummaryFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <TbFileReport id="hrsSummary" className="fa-2x hover" />
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    function hrsGrantedFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <div>{row.mrRequest.grantedHours}</div>
            );
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    function hrsPaidFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <div>{row.totalHoursPaid}</div>
            );
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    function hrsRemainingFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <div>{row.hrsRemaining}</div>
            );
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    const columns = [{
        dataField: 'mrRequest.requestID',
        text: 'Request ID',
        hidden: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        formatter: awardedFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '50px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestDate',
        text: 'Request Date',
        formatter: dateFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'contractorName',
        text: 'Contractor',
        sort: true
    }, {
        dataField: 'mrRequest.projectName',
        text: 'Project Details',
        formatter: detailsFormatter,
        sort: true
    }, {
        dataField: 'jobName',
        text: 'Job Details',
        sort: true
    }, {
        dataField: 'agentName',
        text: 'Agent',
        sort: true
    }, {
        dataField: 'mrRequest.grantedHours',
        text: 'Hrs Granted',
        formatter: hrsGrantedFormatter,
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'totalHoursPaid',
        text: 'Hrs Paid',
        formatter: hrsPaidFormatter,
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'hrsRemaining',
        text: 'Hrs Remaining',
        formatter: hrsRemainingFormatter,
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'dum2',
        isDummyField: true,
        text: "Archived",
        formatter: archivedFormatter,
        headerStyle: { width: '100px', textAlign: 'center' },
        style: { textAlign: 'center' }
    }, {
        dataField: 'dum3',
        isDummyField: true,
        text: 'Request Details',
        formatter: editFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '110px' }
    }, {
        dataField: 'dum4',
        isDummyField: true,
        text: 'Hrs Summary',
        formatter: hrsSummaryFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '110px' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editRequest') {
                LoadRequestDetails(row.mrRequest.requestID, rowIndex)
            }
            else if (e.target.tagName === 'I' && e.target.id === 'deleteRequest') {
                handleDelete(row);
            }
            else if (e.target.tagName === 'svg' && e.target.id === 'hrsSummary') {
                DownloadHoursSummaryReport(row.mrRequest.requestID, row.mrRequest.projectName, row.mrRequest.typeOfHours);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            console.log('here 2');
            LoadRequestDetails(row.mrRequest.requestID, rowIndex);
        }
    };

    function LoadRequestDetails(requestID, rowIndex) {
        Axios.get(`/api/GetRequestById`, {
            params: {
                requestID: requestID
            }
        }).then(response => {
            setState({
                ...state,
                selectedRequestIdx: rowIndex,
                selectedRequest: response.data,
                selectedRequestId: requestID,
                showRequestDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' }, 
        hideSelectColumn: true,
        selected: [state.selectedRequestId]
    };

    function DownloadHoursSummaryReport(requestID, projectName, typeOfHours) {
        let myFileName = "";

        myFileName = "Hours_Summary_For_Request_Report";

        const key = "loading";

        let postdata = {
            requestID: requestID,
            projectName: projectName,
            typeOfHours: typeOfHours
        }

        message.loading({
            content: 'Report generation in progress...',
            key,
            duration: 0,
            style: {
                position: "fixed",
                bottom: 0,
                left: 0
            },
        });

        Axios.post(`/api/DownloadHoursSummaryForRequestReport`, postdata, {
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = myFileName + ".xlsx";
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        if (idxToDirectTo !== null) {
            const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

            await setTableControl({ ...tableControl, page });
        } else {
            idxToDirectTo = 0
        }

        setState({
            ...state,
            requests,
            selectedRequest: requests[idxToDirectTo],
            dataLoaded: true,
            showRequestDetailsSlider: false
        });
    }

    function onContractorChange(value) {
        setValue('formFields.contractor', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function onAgentChange(value) {
        setValue('formFields.requestAgent', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state,
            showRequestDetailsSlider: false
        });
    }

    function handleSearch() {
        if (CheckForValidDateRange() === false) {
            toast.error("Invalid Report Date Range Specified");
            return;
        }

        setState({
            ...state,
            dataLoaded: false
        });

        GetFilteredRequests();
    }

    function CheckForValidDateRange() {
        //No dates entered so considered OK
        if (getValues().formFields.fromDate === null && getValues().formFields.toDate === null) {
            return true;
        }
        else if (getValues().formFields.fromDate <= getValues().formFields.toDate) {
            return true;
        }
        else {
            //this indicates either one of the date fields is null OR the From date is > To date
            return false;
        }
    }

    function handleReset() {
        setState({
            ...state,
            dataLoaded: false
        });

        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null);
        setValue('formFields.job', null);
        setValue('formFields.archived', 'Active');
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);

        GetFilteredRequests();
    }

    //state.requests.forEach((req, idx) => {
    //    console.log(idx, "===>", req.mrRequest.requestID);
    //});

/*    console.log(state);*/

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            MANAGE CONTRACTOR REQUESTS
                        </h2>

                        {/*<button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>*/}
                        {/*    Add Contractor*/}
                        {/*</button>*/}
                    </div>
                    <Fragment>
                        <br />
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <label className="form-label">Agent</label><br />
                                <Select
                                    {...register("formFields.requestAgent")}
                                    size="large" placeholder="All"
                                    style={{ width: 200 }}
                                    showSearch
                                    allowClear={false}
                                    optionFilterProp="children"
                                    onChange={onAgentChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.requestAgent}
                                >
                                    <option value="All">All</option>
                                    {agentsListData?.length > 0 && agentsListData?.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Select Status</label>
                                <select className="form-control-custom" {...register("formFields.requestStatus")}>
                                    <option>All</option>
                                    <option>Awarded</option>
                                    <option>Completed</option>
                                    <option>Denied</option>
                                    <option>Granted</option>
                                    <option>Lost</option>
                                    <option>Pending</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Select Contractor <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contractor")}
                                    size="large" placeholder="Select Contractor"
                                    style={{ width: 400 }}
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onContractorSearch}
                                    onChange={onContractorChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.contractor}
                                >
                                    {/*                    <Option value="jack">Jack</Option>*/}
                                    {/*<option value='' />*/}
                                    {contractorsListData?.length > 0 && contractorsListData?.map((contractor, idx) => <option index={idx} key={contractor.contractors_ID} value={contractor.contractors_ID}>{contractor.name}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Select Job</label><br />
                                <Select
                                    {...register("formFields.job")}
                                    size="large" placeholder="Select Job"
                                    style={{ width: 400 }}
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onJobSearch}
                                    onChange={onJobChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.job}
                                >
                                    {/*<option value='' />*/}
                                    {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Request From Date</label>
                                <Controller
                                    name="formFields.fromDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                            onChange={date => setValue('formFields.fromDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Request To Date</label>
                                <Controller
                                    name="formFields.toDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                            onChange={date => setValue('formFields.toDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            {/*<div className="form-group col-lg-1">*/}
                            {/*    <label className="form-label">Show Archived Requests</label>*/}
                            {/*    <div className="form-control-custom no-border">*/}
                            {/*        <div className="custom-control custom-checkbox custom-control-inline">*/}
                            {/*            <input {...register("formFields.archived")} type="checkbox" className="custom-control-input" />*/}
                            {/*            <label className="custom-control-label"></label>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group col-lg-1">
                                <label className="form-label">Archived Status</label>
                                <select className="form-control-custom" {...register("formFields.archived")}>
                                    <option>Active</option>
                                    <option>Archived</option>
                                    <option>Both</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <button type="button" className="btn btn-submit margin-0-10" onClick={handleSearch}>
                                    Search
                                </button>&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-primary margin-0-10" onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                        <hr style={{ border: "1px solid" }} />

                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Requests"
                                        description="Please stand by while we retrieve requests matching your selection criteria"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {state.requests.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Market Recovery Requests found matching your search criteria
                                                </span>
                                            } />
                                            :
                                            <div className="fullTable">
                                                <img alt="mailflag" className="ms-2" src={mailflag} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded<br />
                                                <img alt="mailflagred" className="ms-2" src={mailflagred} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded at $10 per hour or more<br />
                                                <ToolkitProvider
                                                    keyField='mrRequest.requestID'
                                                    data={state.requests}
                                                    columns={columns}
                                                    search
                                                >
                                                    {props => (
                                                        <div>
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                filterPosition="bottom"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={paginationFactory(options)}
                                                                rowEvents={rowEvents}
                                                                selectRow={selectRow}
                                                            />
                                                        </div>
                                                    )}
                                                </ToolkitProvider>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>                       
                        </div>
                        {state.showRequestDetailsSlider &&
                            <MRRequestDetailsSlider showPane={state.showRequestDetailsSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={getValues().formFields?.contractor}
                                selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}