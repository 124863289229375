import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';

import { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import CVContactDetailsSlider from './CVContactDetailsSlider';

export default function CVContactsTab(props) {
    const [state, setState] = useState({
        selectedContractorId: '',
        contacts: [],
        dataLoaded: false,
        showContactDetailsSlider: false,
        contactEditMode: 'Add',
        selectedContactIdx: null,
        selectedContact: {}
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { SearchBar, ClearSearchButton } = Search;

    useEffect(() => {
        var alive = true;

        if (alive && props.selectedContractorId !== null) {
            Axios.get(`/api/GetContractorContacts`, {
                params: {
                    contractorID: props.selectedContractorId
                }
            }).then(response => {
                let contactsList = response.data;

                setState({
                    ...state,
                    contacts: contactsList,
                    dataLoaded: true,
                    selectedContractorId: props.selectedContractorId
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };

    }, [props]);

    function editFormatter(cell, row) {
        return (
            <i id="editContact" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    function deleteFormatter(cell, row) {
        return (
            <i id="deleteContact" className="far fa-trash-alt fa-1x ml-3 hover text-danger"></i>
        );
    }

    const columns = [{
        dataField: 'contacts_ID',
        text: 'contacts_ID',
        hidden: true
    }, {
        dataField: 'contactName',
        text: 'Contact Name',
        sort: true
    }, {
        dataField: 'contactTitle',
        text: 'Title',
        sort: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: 'Contact Details',
        formatter: editFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }, {
        dataField: 'dum3',
        isDummyField: true,
        text: 'Delete Contact',
        formatter: deleteFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editContact') {
                LoadContactDetails(row.contacts_ID, rowIndex)
            }
            else if (e.target.tagName === 'I' && e.target.id === 'deleteContact') {
                handleDelete(row);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            LoadContactDetails(row.contacts_ID, rowIndex);
        }
    };

    function LoadContactDetails(contactID, rowIndex) {
        Axios.get(`/api/GetContactById`, {
            params: {
                contactID: contactID
            }
        }).then(response => {
            setState({
                ...state,
                selectedContactIdx: rowIndex,
                selectedContact: response.data,
                selectedContactId: contactID,
                showContactDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' },
        hideSelectColumn: true
    };

    function handleAddContact() {
        setState({
            ...state,
            showContactDetailsSlider: true,
            buttonLabel: 'Save New Contact',
            modalHeader: 'Add New Contact',
            contactEditMode: 'Add',
            formFields: {},
            selectedContactId: null,
            selectedContactName: ''
        });
    }

    function handleDelete(contact) {
        confirm({
            title: "You are about to permanently delete this Contact",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteContact`, contact
                ).then(response => {
                    if (response.data) {
                        toast.success("Contact has been deleted");

                        setState({
                            ...state,
                            contacts: response.data,
                            showContactDetailsSlider: false
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateTableDisplay(contacts, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        console.log(idxToDirectTo);

        if (idxToDirectTo === null || idxToDirectTo === 0) {
            idxToDirectTo = 0
        } else {
            const page = Math.ceil(idxToDirectTo / tableControl.sizePerPage);
            await setTableControl({ ...tableControl, page });
        }

        setState({
            ...state,
            contacts,
            selectedContact: contacts[idxToDirectTo],
            dataLoaded: true,
            showContactDetailsSlider: false
        });
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Contractor Contacts</span></Card.Header>
                <Card.Body>
                    <div className="panel-hdr noTitle">
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddContact}>
                            Add Contact
                        </button>
                    </div>
                    <div>
                        {state.dataLoaded &&
                            <Fragment>
                                {state.contacts.length === 0 ?
                                    <div>No Contacts Found</div>
                                    :
                                    <div className="fullTable">
                                        <ToolkitProvider
                                            keyField='contacts_ID'
                                            data={state.contacts}
                                            columns={columns}
                                        >
                                            {props => (
                                                <div>
                                                    {/*<SearchBar {...props.searchProps} style={{ width: "600px", height: "40px" }} placeholder='Search by Project Name' /><br />*/}
                                                    {/*<button onClick={() => props.searchProps.onClear()}>Clear Search Criteria</button>*/}
                                                    {/*<hr style={{ border: "1px solid" }} />*/}
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        striped
                                                        hover
                                                        condensed
                                                        pagination={paginationFactory(options)}
                                                        rowEvents={rowEvents}
                                                        selectRow={selectRow}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>
                    {state.showContactDetailsSlider &&
                        <CVContactDetailsSlider showPane={state.showContactDetailsSlider} selectedContactId={state.selectedContactId} contacts={state.contacts} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedContactIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showContactDetailsSlider: false })} />
                    }
                </Card.Body>
            </Card>
        </div>
    );
}