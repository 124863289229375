import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Input, AutoComplete, Skeleton } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";
import { Select, Spin, Alert, Empty } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import Card from 'react-bootstrap/Card';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';
import mailflag from '../../img/MailFlag.png';
import mailflagred from '../../img/MailFlagRed.png';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';

export default function RequestArchiver() {
    const { SearchBar } = Search;
    const { Option } = Select;
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        requests: [],
        dataLoaded: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        formFields: {},
        selectedRequestId: null
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [agentsListData, setAgentsListData] = useState([]);

    const [contractorsListData, setContractorsListData] = useState([]);

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [jobsListData, setJobsListData] = useState([]);

    useEffect(() => {
        //const subscription = watch((value, { name, type }) => {
        //    if (name === 'formFields.requestAgent' || name === 'formFields.requestStatus' || name === 'formFields.contractor' || name === 'formFields.job') {
        //        //console.log('about to call GetFilteredRequests...');
        //        //console.log('search criteria changed...');
        //        //GetFilteredRequests();
        //    }
        //});

        setValue('formFields', {});
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null)
        setValue('formFields.pastCompletionDate', false)
        setValue('formFields.notAwarded120', false)
        setValue('formFields.allGrantedHoursBilled', false)
        setValue('formFields.hideHrsRemaining', true)
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.job', null);

        setState({
            ...state
        });

        //console.log('about to initialize...');
        InitializeRequestManager();

        /*return () => { subscription.unsubscribe(); };*/
    }, []);

    function InitializeRequestManager() { 
        Axios.get(`/api/InitializeRequestManager`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: 'Active'
            }
        }).then(response => {
            let requestsList = response.data.requests;
            let agentsList = response.data.agents;
            let contractorsList = response.data.contractors;
            let jobsList = response.data.jobs;

            console.log(requestsList);

            setAgentsListData(agentsList);
            setContractorsListData(contractorsList);
            setJobsListData(jobsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetFilteredRequests() {
        let specialArchiveGet = '';

        if (getValues().formFields.pastCompletionDate === true) {
            specialArchiveGet = 'pastCompletionDate';
        }
        if (getValues().formFields.notAwarded120 === true) {
            specialArchiveGet = 'notAwarded120';
        }
        if (getValues().formFields.allGrantedHoursBilled === true) {
            specialArchiveGet = 'allGrantedHoursBilled';
        }

        Axios.get(`/api/GetFilteredRequests`, {
            params: {
                contractorID: getValues().formFields.contractor,
                requestStatus: getValues().formFields.requestStatus,
                requestAgent: getValues().formFields.requestAgent,
                jobID: getValues().formFields.job,
                archived: false,
                specialArchiveGet: specialArchiveGet,
                fromDate: getValues().formFields.fromDate,
                toDate: getValues().formFields.toDate
            }
        }).then(response => {
            let requestsList = response.data;
            console.log(requestsList);

            setState({
                ...state,
                requests: requestsList,
                dataLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function awardedFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Awarded') {
            if (row.mrRequest.dollarsPerHour < 10) {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflag} style={{ height: '15px' }} /></span>
                );
            }
            else {
                return (
                    <span><img alt="awardedflag" className="ms-2" src={mailflagred} style={{ height: '15px' }} /></span>
                );
            }
        }
        else {
            return (
                <span></span>
            );
        }
    }

    function requestdateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.mrRequest.requestDate)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    function completiondateFormatter(cell, row) {
        if (row.mrRequest.anticipatedCompletionDate === null) {
            return (
                <div>{mydate}</div>
            );
        }
        else {
            var mydate = Moment(dateWithNoTimezone(row.mrRequest.anticipatedCompletionDate)).format("L");
            return (
                <div>{mydate}</div>
            );
        }
    }

    function detailsFormatter(cell, row) {
        return (
            <div>{row.mrRequest.projectName}<br />Type of Hours: {row.mrRequest.typeOfHours}</div>
        );
    }

    function selectFormatter(cell, row, idx) {
        
        return (
            <input type="checkbox" id="chkSelect" name="chkSelect" defaultChecked={row.selectForArchiving} disabled={row.hrsPending === 0 || userInfo.userType === 'Administrator' ? false : true} />
            //<input type="checkbox" id="chkSelect" name="chkSelect" defaultChecked={row.selectForArchiving} />
        );
    }

    function isColumnHidden() {
        if (getValues().formFields?.hideHrsRemaining === true) {
            return true;
        }
        else {
            return false;
        }
    }

    function isSelectCheckBoxHidden(cell, row) {
        console.log(row);

        //if (row.hrsPending === 0) {
        //    return false;
        //}
        //else {
        //    return true;
        //}
    }

    function hrsGrantedFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <div>{row.mrRequest.grantedHours}</div>
            );
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    function hrsPaidFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <div>{row.totalHoursPaid}</div>
            );
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    function hrsRemainingFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            return (
                <div>{row.hrsRemaining}</div>
            );
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    function hrsPendingFormatter(cell, row) {
        if (row.mrRequest.requestStatus === 'Granted' || row.mrRequest.requestStatus === 'Awarded' || row.mrRequest.requestStatus === 'Completed') {
            if (row.hrsPending === 0) {
                return (
                    <div>{row.hrsPending}</div>
                );
            }
            else {
                return (
                    <div style={{color: 'red'}}>{row.hrsPending}</div>
                );
            }
        }
        else {
            return (
                <div>N/A</div>
            );
        }
    }

    const columns = [{
        dataField: 'mrRequest.requestID',
        text: 'Request ID',
        hidden: true
    }, {
        dataField: 'selectForArchiving',
        formatter: selectFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '50px', textAlign: 'center' },
    }, {
        dataField: 'dum1',
        isDummyField: true,
        formatter: awardedFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '50px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestDate',
        text: 'Request Date',
        formatter: requestdateFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.anticipatedCompletionDate',
        text: 'Est Completion Date',
        formatter: completiondateFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'contractorName',
        text: 'Contractor',
        sort: true
    }, {
        dataField: 'mrRequest.projectName',
        text: 'Project Details',
        formatter: detailsFormatter,
        sort: true
    }, {
        dataField: 'jobName',
        text: 'Job Details',
        sort: true
    }, {
        dataField: 'mrRequest.typeOfHours',
        text: 'Type of Hours',
        sort: true,
        headerStyle: { width: '150px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.grantedHours',
        text: 'Hrs Granted',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' },
        formatter: hrsGrantedFormatter,
        //hidden: isColumnHidden()
    }, {
        dataField: 'totalHoursPaid',
        text: 'Hrs Paid',
        formatter: hrsPaidFormatter,
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'hrsRemaining',
        text: 'Hrs Remaining',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' },
        formatter: hrsRemainingFormatter,
        //hidden: isColumnHidden()
    }, {
        dataField: 'hrsPending',
        text: 'Submitted Hrs Pending Payment',
        sort: true,
        style: { textAlign: 'center' },
        headerStyle: { width: '120px', textAlign: 'center' },
        formatter: hrsPendingFormatter,
    }, {
        dataField: 'agentName',
        text: 'Agent',
        sort: true,
        headerStyle: { width: '200px', textAlign: 'center' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.id === 'chkSelect') {
                //includeOnCheck checkbox clicked so update state so it holds across pages
                var indexAcrossAllPages = ((tableControl.page - 1) * tableControl.sizePerPage) + rowIndex;

                var newList = [...state.requests];
                var itemToUpdate = newList[indexAcrossAllPages];

                itemToUpdate.selectForArchiving = !itemToUpdate.selectForArchiving;
                newList[indexAcrossAllPages] = itemToUpdate;

                setState({
                    ...state,
                    requests: newList
                });
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            
        }
    };

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' },
        hideSelectColumn: true,
        selected: [state.selectedRequestId]
    };

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        if (idxToDirectTo !== null) {
            const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

            await setTableControl({ ...tableControl, page });
        } else {
            idxToDirectTo = 0
        }

        console.log(requests[idxToDirectTo]);

        setState({
            ...state,
            requests,
            selectedRequest: requests[idxToDirectTo],
            dataLoaded: true
        });
    }

    function onContractorChange(value) {
        setValue('formFields.contractor', value)

        setState({
            ...state
        });
    }

    function onAgentChange(value) {
        setValue('formFields.requestAgent', value)

        setState({
            ...state
        });
    }

    function handleSearch() {
        if (CheckForValidDateRange() === false) {
            toast.error("Invalid Report Date Range Specified");
            return;
        }

        if (getValues().formFields?.notAwarded120 === true) {
            setValue('formFields.hideHrsRemaining', true);
        }
        else {
            setValue('formFields.hideHrsRemaining', false);
        }

        GetFilteredRequests();
    }

    function CheckForValidDateRange() {
        //No dates entered so considered OK
        if (getValues().formFields.fromDate === null && getValues().formFields.toDate === null) {
            return true;
        }
        else if (getValues().formFields.fromDate <= getValues().formFields.toDate) {
            return true;
        }
        else {
            //this indicates either one of the date fields is null OR the From date is > To date
            return false;
        }
    }

    function handleReset() {
        if (userInfo.userType === 'Agent' || userInfo.userType === 'SuperAgent') {
            setValue('formFields.requestAgent', userInfo.userId);
        }
        else {
            setValue('formFields.requestAgent', 'All');
        }
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.contractor', null)
        setValue('formFields.pastCompletionDate', false)
        setValue('formFields.notAwarded120', false)
        setValue('formFields.allGrantedHoursBilled', false)
        setValue('formFields.hideHrsRemaining', true);
        setValue('formFields.fromDate', null);
        setValue('formFields.toDate', null);
        setValue('formFields.job', null);

        GetFilteredRequests();
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state
        });
    }

    function handleNotAwarded120Clicked() {
        setValue('formFields.requestStatus', 'All');
        setValue('formFields.pastCompletionDate', false);
        setValue('formFields.allGrantedHoursBilled', false);

        setState({
            ...state
        });
    }

    function handlePastCompletionDateClicked() {
        setValue('formFields.requestStatus', 'Awarded');
        setValue('formFields.notAwarded120', false);
        setValue('formFields.allGrantedHoursBilled', false);

        setState({
            ...state
        });
    }

    function handleAllGrantedHoursBilledClicked() {
        setValue('formFields.requestStatus', 'Awarded');
        setValue('formFields.pastCompletionDate', false);
        setValue('formFields.notAwarded120', false);

        setState({
            ...state
        });
    }

    function archiveSelectedRequests() {
        var itemsSelected = 0;
        let myConfirmTitle = "";

        state.requests.forEach((request, idx) => {
            if (request.selectForArchiving === true) {
                itemsSelected += 1;
            }
        });

        if (itemsSelected === 0) {
            toast.error("No requests were selected for archiving");

            return;
        }

        if (itemsSelected === 1) {
            myConfirmTitle = "You are about to Archive 1 request";
        }
        else {
            myConfirmTitle = "You are about to Archive " + itemsSelected.toString() + " requests";
        }

        confirm({
            title: myConfirmTitle,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                archiveRequests(itemsSelected);

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function archiveRequests(itemsSelected) {
        var itemsArchived = 0;

        await state.requests.forEach((request, idx) => {
            if (request.selectForArchiving === true) {
                console.log(request.mrRequest.requestID);

                //Archive Job if no other requests are tied to it
                Axios.get(`/api/GetOtherRequestsForJob`, {
                    params: {
                        jobs_ID: request.mrRequest.jobs_ID,
                        requestID: request.mrRequest.requestID
                    }
                }).then(response => {
                    if (response.data.length === 0) {
                        //Archive Job if no other active requests are tied to it
                        let postdata = {
                            jobs_ID: request.mrRequest.jobs_ID
                        }

                        Axios.post(`/api/ArchiveJob`, postdata
                        ).then(response => {

                        }).catch(error => {
                            console.log(error);
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                //Archive selected request and create history record
                let postdata = {
                    RequestID: request.mrRequest.requestID,
                    Username: userInfo.fullName,
                    archived: 1
                }

                Axios.post(`/api/ToggleRequestArchive`, postdata
                ).then(response => {
                    itemsArchived += 1;

                }).catch(error => {
                    console.log(error);
                });
            }
        });

        GetFilteredRequests();

        if (itemsSelected === 1) {
            toast.success("1 request was successfully archived");
        }
        else {
            toast.success(itemsSelected.toString() + " requests were successfully archived");
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            ARCHIVE REQUESTS
                        </h2>

                        {/*<button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>*/}
                        {/*    Add Contractor*/}
                        {/*</button>*/}
                    </div>
                    <Fragment>
                        <br />
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <label className="form-label">Agent</label><br />
                                <Select
                                    {...register("formFields.requestAgent")}
                                    size="large" placeholder="All"
                                    style={{ width: 200 }}
                                    showSearch
                                    allowClear={false}
                                    optionFilterProp="children"
                                    onChange={onAgentChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.requestAgent}
                                >
                                    <option value="All">All</option>
                                    {agentsListData?.length > 0 && agentsListData?.map((agent, idx) => <option index={idx} key={agent.users_ID} value={agent.users_ID}>{agent.firstName} {agent.lastName}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Select Status</label>
                                <select className="form-control-custom" {...register("formFields.requestStatus")}>
                                    <option>All</option>
                                    <option>Awarded</option>
                                    <option>Completed</option>
                                    <option>Denied</option>
                                    <option>Granted</option>
                                    <option>Lost</option>
                                    <option>Pending</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Select Contractor <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contractor")}
                                    size="large" placeholder="Select Contractor"
                                    style={{ width: 400 }}
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onContractorSearch}
                                    onChange={onContractorChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.contractor}
                                >
                                    {/*                    <Option value="jack">Jack</Option>*/}
                                    {/*<option value='' />*/}
                                    {contractorsListData?.length > 0 && contractorsListData?.map((contractor, idx) => <option index={idx} key={contractor.contractors_ID} value={contractor.contractors_ID}>{contractor.name}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Select Job</label><br />
                                <Select
                                    {...register("formFields.job")}
                                    size="large" placeholder="Select Job"
                                    style={{ width: 400 }}
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onJobSearch}
                                    onChange={onJobChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.job}
                                >
                                    {/*<option value='' />*/}
                                    {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Request From Date</label>
                                <Controller
                                    name="formFields.fromDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.fromDate ? Moment(getValues().formFields.fromDate).toDate() : null}
                                            onChange={date => setValue('formFields.fromDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-1">
                                <label className="form-label">Request To Date</label>
                                <Controller
                                    name="formFields.toDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.toDate ? Moment(getValues().formFields.toDate).toDate() : null}
                                            onChange={date => setValue('formFields.toDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input {...register("formFields.notAwarded120")} type="checkbox" className="custom-control-input" onClick={handleNotAwarded120Clicked} />
                                    <label className="custom-control-label">&nbsp;Get Requests More Than 120 Days From Bid Date That Have Not Been Awarded</label>
                                </div>
                            </div>
                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input {...register("formFields.pastCompletionDate")} type="checkbox" className="custom-control-input" onClick={handlePastCompletionDateClicked} />
                                    <label className="custom-control-label">&nbsp;Get Awarded Requests Where Estimated Completion Date Has Passed And Still Have Hours Remaining</label>
                                </div>
                            </div>
                            <div className="form-group col-lg-12">
                                <div className="custom-control custom-checkbox custom-control-inline">
                                    <input {...register("formFields.allGrantedHoursBilled")} type="checkbox" className="custom-control-input" onClick={handleAllGrantedHoursBilledClicked} />
                                    <label className="custom-control-label">&nbsp;Get Awarded Requests Where All Granted Hours Have Been Billed</label>
                                </div>
                            </div>
                            
                        </div>
                        <p></p>
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <button type="button" className="btn btn-submit margin-0-10" onClick={handleSearch}>
                                    Search
                                </button>&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-primary margin-0-10" onClick={handleReset}>
                                    Reset
                                </button>
                                <button className="btn btn-submit float-right ms-3" onClick={archiveSelectedRequests}>Archive Selected Requests</button>
                            </div>
                        </div>
                        <hr style={{ border: "1px solid" }} />

                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Requests"
                                        description="Please stand by while we retrieve requests matching your selection criteria"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {state.requests.length === 0 ?
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                <span>
                                                    No Market Recovery Requests found matching your search criteria
                                                </span>
                                            } />
                                            :
                                            <div className="fullTable">
                                                <img alt="mailflag" className="ms-2" src={mailflag} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded<br />
                                                <img alt="mailflagred" className="ms-2" src={mailflagred} style={{ height: '15px' }} />&nbsp;Indicates the request was Awarded at $10 per hour or more<br />
                                                <ToolkitProvider
                                                    keyField='mrRequest.requestID'
                                                    data={state.requests}
                                                    columns={columns}
                                                    search
                                                >
                                                    {props => (
                                                        <div>
                                                            <BootstrapTable
                                                                {...props.baseProps}
                                                                filterPosition="bottom"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={paginationFactory(options)}
                                                                selectRow={selectRow}
                                                                rowEvents={rowEvents}
                                                            />
                                                        </div>
                                                    )}
                                                </ToolkitProvider>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>                       
                        </div>
                    </Fragment>
                </div>
            </div>
        </div>
    );
}