import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Spin, Alert, Empty } from 'antd';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import JobDetailsSlider from './JobDetailsSlider';

export default function JobManager(props) {
    const [state, setState] = useState({
        jobs: [],
        dataLoaded: false,
        showJobDetailsSlider: false,
        jobEditMode: 'Add',
        selectedJobIdx: null,
        selectedJob: {},
        formFields: {},
        selectedJobId: null
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [jobsListData, setJobsListData] = useState([]);

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        setValue('formFields', {});

        setValue('formFields.jobStatus', 'Active');
        setValue('formFields.zoneRegion', 'All Zones')
        setValue('formFields.job', null)

        setState({
            ...state
        });

        //console.log('about to initialize...');
        InitializeJobManager();
    }, []);

    function InitializeJobManager() {
        Axios.get(`/api/InitializeJobManager`, {
            params: {
                jobStatus: 'Active',
                zoneRegion: "All Zones",
                jobs_ID: null
            }
        }).then(response => {
            setValue("matchingJobs", response.data.matchingJobs);

            let jobsList = response.data.jobs;
            setJobsListData(jobsList);

            setState({
                ...state,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function GetFilteredJobs() {
        Axios.get(`/api/GetFilteredJobs`, {
            params: {
                jobStatus: getValues().formFields.jobStatus,
                zoneRegion: getValues().formFields.zoneRegion,
                jobs_ID: getValues().formFields.job
            }
        }).then(response => {
            setValue("matchingJobs", response.data);

            setState({
                ...state,
                dataLoaded: true,
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function editFormatter(cell, row) {
        return (
            <i id="editJob" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    function archivedFormatter(cell, row) {
        if (row.archived) {
            return (
                <div><i id="arch" className="fa fa-check ml-3 hover" /></div>
            );
        }
        else {
            return (
                <div></div>
            );
        }
    }

    const columns = [{
        dataField: 'jobs_ID',
        text: 'jobs_ID',
        hidden: true
    }, {
        dataField: 'jobName',
        text: 'Job Name',
        sort: true
    }, {
        dataField: 'address',
        text: 'Address',
        sort: true
    }, {
        dataField: 'city',
        text: 'City',
        headerStyle: { width: '220px' },
        sort: true
    }, {
        dataField: 'state',
        text: 'State',
        headerStyle: { width: '60px' },
        sort: true
    }, {
        dataField: 'zip',
        text: 'Zip Code',
        headerStyle: { width: '130px' },
        sort: true
    }, {
        dataField: 'region',
        text: 'Region',
        headerStyle: { width: '100px' },
        sort: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: "Archived",
        formatter: archivedFormatter,
        headerStyle: { width: '100px', textAlign: 'center' },
        style: { textAlign: 'center' }
    }, {
        dataField: 'dum2',
        isDummyField: true,
        text: 'Job Details',
        formatter: editFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editJob') {
                LoadJobDetails(row.jobs_ID, rowIndex)
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            LoadJobDetails(row.jobs_ID, rowIndex);
        }
    };

    function LoadJobDetails(jobID, rowIndex) {
        //console.log(jobID);

        setState({
            ...state,
            selectedJobIdx: rowIndex,
            selectedJobId: jobID,
            showJobDetailsSlider: true
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#C5DBEC' },
        hideSelectColumn: true,
        selected: [state.selectedJobId]
    };

    function handleAddNewJob() {
        setState({
            ...state,
            selectedJobIdx: null,
            selectedJobId: null,
            showJobDetailsSlider: true
        });
    }

    function handleReset() {
        setValue('formFields.jobStatus', 'Active');
        setValue('formFields.zoneRegion', 'All Zones')
        setValue('formFields.job', null)

        setState({
            ...state
        });

        GetFilteredJobs();
    }

    function handleSearch() {
        GetFilteredJobs();
    }

    function onJobChange(value) {
        setValue('formFields.job', value)

        setState({
            ...state,
            showJobDetailsSlider: false
        });
    }

    async function updateTableDisplay(jobs, idxToDirectTo = null) {
        console.log(idxToDirectTo);

        await setState({ ...state, dataLoaded: false });

        if (idxToDirectTo !== null) {
            const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

            await setTableControl({ ...tableControl, page });
        } else {
            idxToDirectTo = 0
        }

        setState({
            ...state,
            jobs,
            dataLoaded: true,
            showJobDetailsSlider: false
        });
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            JOB MANAGER
                        </h2>
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddNewJob}>
                            Add New Job
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <label className="form-label">Job Status</label><br />
                                <select className="form-control-custom" {...register("formFields.jobStatus")}>
                                    <option>Active</option>
                                    <option>Archived</option>
                                    <option>All</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Zone/Region</label>
                                <select className="form-control-custom" {...register("formFields.zoneRegion")}>
                                    <option>All Zones</option>
                                    <option>Z1</option>
                                    <option>Z2</option>
                                    <option>Z3</option>
                                    <option>Z4</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Select Job</label><br />
                                <Select
                                    {...register("formFields.job")}
                                    size="large" placeholder="Select Job"
                                    style={{ width: 500 }}
                                    showSearch
                                    allowClear={true}
                                    optionFilterProp="children"
                                    //onSearch={onJobSearch}
                                    onChange={onJobChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={getValues().formFields?.job}
                                >
                                    {/*<option value='' />*/}
                                    {jobsListData?.length > 0 && jobsListData?.map((job, idx) => <option index={idx} key={job.jobs_ID} value={job.jobs_ID}>{job.jobName}</option>)}
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <button type="button" className="btn btn-submit margin-0-10" onClick={handleSearch}>
                                    Search
                                </button>&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-outline-primary margin-0-10" onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                        <hr style={{ border: "1px solid" }} />

                        <div>
                            <Spin spinning={!state.dataLoaded}>
                                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                    <Alert
                                        message="Loading Market Recovery Jobs"
                                        description="Please stand by while we retrieve jobs matching your selection criteria"
                                        type="info"
                                    />
                                </div>
                                {state.dataLoaded &&
                                    <Fragment>
                                        {getValues().matchingJobs?.length === 0 ?
                                            <div>No Matching Jobs Found</div>
                                            :
                                            <div className="fullTable">
                                                <ToolkitProvider
                                                    keyField='jobs_ID'
                                                    data={getValues().matchingJobs}
                                                    columns={columns}
                                                    search
                                                >
                                                    {
                                                        props => (
                                                            <div>
                                                                {/*<ReactTooltip place="left" type="success" effect="solid" />*/}
                                                                {/*<SearchBar {...props.searchProps} />*/}
                                                                <BootstrapTable
                                                                    {...props.baseProps}
                                                                    filterPosition="bottom"
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    pagination={paginationFactory(options)}
                                                                    rowEvents={rowEvents}
                                                                    selectRow={selectRow}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                </ToolkitProvider>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </Spin>
                        </div>
                        {state.showJobDetailsSlider &&
                            <JobDetailsSlider showPane={state.showJobDetailsSlider} selectedJobId={state.selectedJobId} jobs={getValues().matchingJobs}
                                selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showJobDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}