import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import MRRequestDetailsSlider from '../Requests/MRRequestDetailsSlider';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";

import Moment from 'moment';

export default function ContractorRequestsTab(props) {
    const [state, setState] = useState({
        selectedContractorId: '',
        requests: [],
        dataLoaded: false,
        showRequestDetailsSlider: false,
        requestEditMode: 'Add',
        selectedRequestIdx: null,
        selectedRequest: {},
        billingStatus: '',
        agentsList: [],
        formFields: {}
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    const { SearchBar, ClearSearchButton } = Search;

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            //console.log(value);
            //console.log(name);
            //console.log(type);

            //if (typeof (getValues().selectedContractorId) !== 'undefined') {
            //    loadContratorRequests();
            //}
        });

        return () => { subscription.unsubscribe(); };

    }, []);

    useEffect(() => {
        var alive = true;

        

        //setValue('formFields', {});
        //setValue('formFields.requestAgent', 'All');
        //setValue('formFields.requestStatus', 'All');
        //setValue('selectedContractorId', props.selectedContractorId)

        if (alive && props.selectedContractorId !== null) {
            //loadContratorRequests();

            Axios.get(`/api/GetContractorRequests`, {
                params: {
                    contractorID: props.selectedContractorId
                }
            }).then(response => {
                let requestsList = response.data;

                setState({
                    ...state,
                    requests: requestsList,
                    dataLoaded: true,
                });
            }).catch(error => {
                console.log(error);
            });
        }
    }, [props]);

    //function loadContratorRequests() { 
    //    Axios.get(`/api/GetContractorRequests`, {
    //        params: {
    //            contractorID: getValues().selectedContractorId,
    //            requestStatus: getValues().formFields.requestStatus,
    //            requestAging: state.selectedRequestStatus,
    //            requestBillingStatus: state.selectedBillingStatus,
    //            requestAgent: getValues().formFields.requestAgent
    //        }
    //    }).then(response => {
    //        let requestsList = response.data.requests;
    //        let agentsList = response.data.agents;

    //        setState({
    //            ...state,
    //            agentsList: agentsList,
    //            requests: requestsList,
    //            dataLoaded: true,
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function handleTableChange(type, { page, sizePerPage }) {

    }

    const searchProps = {
        delay: 800,
        placeholder: "type something...",
        defaultSearch: 'anyx'
    };

    function editFormatter(cell, row) {
        return (
            <i id="editRequest" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    function deleteFormatter(cell, row) {
        return (
            <i id="deleteRequest" className="far fa-trash-alt fa-1x ml-3 hover text-danger"></i>
        );
    }

    function detailsFormatter(cell, row) {
        return (
            <div>{row.mrRequest.projectName}<br />Type of Hours: {row.mrRequest.typeOfHours}</div>
        );
    }

    function dateFormatter(cell, row) {
        //console.log(row.requestDate);
        //console.log(dateWithNoTimezone(row.requestDate));

        //var mydate = new Date(Date.parse(row.requestDate)).toLocaleString();

        var mydate = Moment(dateWithNoTimezone(row.mrRequest.requestDate)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    const columns = [{
        dataField: 'mrRequest.requestID',
        text: 'requestID',
        hidden: true
    }, {
        dataField: 'mrRequest.requestDate',
        text: 'Request Date',
        formatter: dateFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.projectName',
        text: 'Project Details',
        formatter: detailsFormatter,
        sort: true
    }, {
        dataField: 'jobName',
        text: 'Job Details',
        sort: true
    }, {
        dataField: 'agentName',
        text: 'Agent',
        sort: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: 'Request Details',
        formatter: editFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }, {
        dataField: 'dum3',
        isDummyField: true,
        text: 'Delete Request',
        formatter: deleteFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px' }
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editRequest') {
                LoadRequestDetails(row.mrRequest.requestID, rowIndex)
            }
            else if (e.target.tagName === 'I' && e.target.id === 'deleteRequest') {
                handleDelete(row);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            console.log(row);
            LoadRequestDetails(row.mrRequest.requestID, rowIndex);
        }
    };

    function LoadRequestDetails(requestID, rowIndex) {
        Axios.get(`/api/GetRequestById`, {
            params: {
                requestID: requestID
            }
        }).then(response => {
            console.log(response.data);

            setState({
                ...state,
                selectedRequestIdx: rowIndex,
                selectedRequest: response.data.mrRequest,
                selectedRequestId: requestID,
                showRequestDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: 'lightgrey',
        hideSelectColumn: true,
        selected: [state.selectedRequest?.requestID || null]
    };

    function handleAddRequest() {
        setState({
            ...state,
            showRequestDetailsSlider: true,
            buttonLabel: 'Save New Request',
            modalHeader: 'Add New Request',
            requestEditMode: 'Add',
            formFields: {},
            selectedRequestId: null,
            selectedContactName: ''
        });
    }

    function handleDelete(request) {
        confirm({
            title: "You are about to permanently delete this Request",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteRequest`, request
                ).then(response => {
                    if (response.data) {
                        toast.success("Request has been deleted");

                        setState({
                            ...state,
                            requests: response.data,
                            showRequestDetailsSlider: false
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        if (idxToDirectTo !== null) {
            const page = Math.ceil(idxToDirectTo / tableControl.sizePerPage);

            await setTableControl({ ...tableControl, page });
        } else {
            idxToDirectTo = 0
        }

        setState({
            ...state,
            requests,
            selectedRequest: requests[idxToDirectTo],
            dataLoaded: true,
            showRequestDetailsSlider: false
        });
    }

    function handleSearchCriteriaChange(e) {
        setState({
            ...state,
            billingStatus: e.target.value
        });
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Contractor Market Recovery Requests</span></Card.Header>
                <Card.Body>
                    <div className="panel-hdr noTitle">
                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddRequest}>
                            Add Market Recovery Request
                        </button>
                    </div>
                    <div className="row color-dark-blue">
                        Specify Selection Criteria
                    </div>
                    <div className="row">
                        {/*<div className="form-group col-lg-2">*/}
                        {/*    <label className="form-label">Select Status</label>*/}
                        {/*    <select className="form-control-custom" {...register("formFields.requestStatus")}>*/}
                        {/*        <option>All</option>*/}
                        {/*        <option>Awarded</option>*/}
                        {/*        <option>Completed</option>*/}
                        {/*        <option>Denied</option>*/}
                        {/*        <option>Granted</option>*/}
                        {/*        <option>Lost</option>*/}
                        {/*        <option>Pending</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className="form-group col-lg-2">*/}
                        {/*    <label className="form-label">Select Aging</label>*/}
                        {/*    <select className="form-control-custom" {...register("formFields.requestAging")}>*/}
                        {/*        <option>All</option>*/}
                        {/*        <option>Last 30 Days</option>*/}
                        {/*        <option>Last 60 Days</option>*/}
                        {/*        <option>Last 90 Days</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className="form-group col-lg-2">*/}
                        {/*    <label className="form-label">Billing Status</label>*/}
                        {/*    <select className="form-control-custom" {...register("formFields.requestBillingStatus")}>*/}
                        {/*        <option>All</option>*/}
                        {/*        <option>Open</option>*/}
                        {/*        <option>Finalized</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className="form-group col-lg-2">*/}
                        {/*    <label className="form-label">Agent</label>*/}
                        {/*    <select className="form-control-custom" {...register("formFields.requestAgent")}>*/}
                        {/*        <option>All</option>*/}
                        {/*        {state.agentsList?.length > 0 && state.agentsList?.map((agent, idx) => <option index={idx} key={agent.agents_ID} value={agent.agents_ID}>{agent.agentName}</option>)}*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                    </div>
                    <div className="row">
                        &nbsp;
                    </div>
                    <div>
                        {state.dataLoaded &&
                            <Fragment>
                                {state.requests.length === 0 ?
                                    <div>No Market Recovery Requests Found</div>
                                    :
                                    <div className="fullTable">
                                        <ToolkitProvider
                                            keyField='mrRequest.requestID'
                                            data={state.requests}
                                            columns={columns}
                                            search
                                        >
                                            {props => (
                                                <div>
                                                    <SearchBar {...props.searchProps} style={{ width: "600px", height: "40px" }} placeholder='Search by Project Name, Type of Hours or Status' /><br />
                                                    <button onClick={() => props.searchProps.onClear()}>Clear Search Criteria</button>
                                                    <hr style={{ border: "1px solid" }} />
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        filterPosition="bottom"
                                                        noDataIndication="There is no solution"
                                                        striped
                                                        hover
                                                        condensed
                                                        pagination={paginationFactory(options)}
                                                        rowEvents={rowEvents}
                                                        selectRow={selectRow}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>
                    {state.showRequestDetailsSlider &&
                        <MRRequestDetailsSlider showPane={state.showRequestDetailsSlider} selectedRequestId={state.selectedRequestId} requests={state.requests} selectedContractorId={state.selectedContractorId}
                            selectedIdx={state.selectedIdx} updateTableDisplay={updateTableDisplay} hidePane={() => setState({ ...state, showRequestDetailsSlider: false })} />
                    }
                </Card.Body>
            </Card>
        </div>
    );
}