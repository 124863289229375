import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import statesList from '../Helpers/StatesList';
import NumberFormat from 'react-number-format';

export default function CVContactDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        contacts: [],
        selectedContactId: '',
        selectedIdx: '',
        formFields: {},
        btnSubmitClicked: false,
        buttonLabel: '',
        states: statesList,
        selectedContractorId: ''
    });

    useEffect(() => {
        //setState({
        //    ...state,
        //    states: statesList,
        //    showPane: true
        //});
    }, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedContactId !== null && props.selectedContactId !== "") {
                Axios.get(`/api/GetContactById`, {
                    params: {
                        contactID: props.selectedContactId
                    }
                }).then(response => {
                    setState({
                        ...state,
                        formFields: response.data,
                        showPane: props.showPane,
                        contacts: props.contacts,
                        selectedContactId: props.selectedContactId,
                        selectedContractorId: props.selectedContractorId,
                        selectedIdx: props.selectedIdx,
                        buttonLabel: 'Update Contact Info'
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                setState({
                    contacts: props.contacts,
                    selectedContractorId: props.selectedContractorId,
                    formFields: {},
                    showPane: props.showPane,
                    buttonLabel: 'Add New Contact'
                }, () => console.log('hello'));
            }
        }
    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            formFields: { ...state.formFields, [name]: value }
        });
    }

    function handleSubmit() {
        let formFields = state.formFields;

        if (!state.formFields?.contactName) {
            toast.error("Mandatory fields missing!");
            return;
        } else {
            if (state.selectedContactId) {
                Axios.post(`/api/UpdateContact`, formFields
                ).then(response => {
                    toast.success("Contact Information Successfully Updated");

                    //now update the parent list
                    //the next line basically creates kvp with an object named memberModel 
                    let contacts = state.contacts;

                    const selectedIdx = contacts.findIndex(a => a.contacts_ID === state.formFields.contacts_ID);

                    contacts[selectedIdx] = state.formFields;

                    props.updateTableDisplay(contacts, selectedIdx);

                    /*hidePane();*/
                }).catch(error => {
                    toast.error("Problem Encountered Updating Contact");

                    console.log(error);
                });
            }
            else {
                formFields.contractors_ID = state.selectedContractorId;

                Axios.post(`/api/AddNewContact`, formFields
                ).then(response => {
                    if (response.data) {
                        toast.success("New Contact Successfully Added");

                        //now update the parent list
                        let contacts = response.data.contacts;

                        const idxToDirectTo = contacts.findIndex(a => a.contacts_ID === response.data.newID);

                        props.updateTableDisplay(contacts, idxToDirectTo);
                    }
                    //hidePane();
                }).catch(error => {
                    toast.error("Problem Encountered Adding New Contact");

                    console.log(error);
                });
            }
        }
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Contact Info'
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <Fragment>
                            <div className="panel-hdr">
                                {state.selectedContactId && (<h2>{state.formFields.contactName}</h2>)}
                                {!state.selectedContactId && (<h2>New Contact</h2>)}
                            </div>
                            <Card className="local777Card">
                                <Card.Header><span>Contact Information (*Indicates required field)</span></Card.Header>
                                <Card.Body>
                                    <form>
                                        <div className="row">
                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Contact Name</label>
                                                <input type="text" name="contactName" value={state.formFields.contactName || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Title</label>
                                                <input type="text" name="contactTitle" value={state.formFields.contactTitle || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Email</label>
                                                <input type="text" name="contactEmail" value={state.formFields.contactEmail || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Phone</label>
                                                <input type="text" name="contactPhone" value={state.formFields.contactPhone || ''} onChange={handleInputChange} className="form-control-custom" />
                                            </div>
                                            <div className="form-group col-lg-8">

                                            </div>
                                            {/*<div className="form-group col-lg-4">*/}
                                            {/*    <label className="form-label">Password</label>*/}
                                            {/*    <input type="text" name="contactPassword" value={state.formFields.contactPassword || ''} onChange={handleInputChange} className="form-control-custom" />*/}
                                            {/*</div>*/}
                                            {/*<div className="form-group col-lg-8">*/}

                                            {/*</div>*/}
                                        </div>
                                   
                                    </form>
                                </Card.Body>
                            </Card>
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}