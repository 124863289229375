import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import Axios from '../../config/axios';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import CheckUtilitiesResetHoursSlider from './CheckUtilitiesResetHoursSlider';

export default function CheckUtilities(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        formFields: {},
        btnSubmitClicked: false,
        showResetHoursSlider: false
    });

    const { register, getValues, setValue, control, watch } = useForm();

    useEffect(() => {
        //setState({
        //    ...state,
        //    states: statesList
        //});
    }, []);

    function handleVoidCheck() {
        if (getValues().checkNoToVoid === '') {
            toast.error("You must enter a check number to void");
            return;
        }

        confirm({
            title: "You are about to void check #" + getValues().checkNoToVoid,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.get(`/api/GetItemsOnCheck`, {
                    params: {
                        checkNo: getValues().checkNoToVoid
                    }
                }).then(response => {
                    console.log(response.data);
                    if (response.data > 0) {
                        let postdata = {
                            originalCheckNo: getValues().checkNoToVoid
                        }

                        //set selected request to 'Lost'
                        Axios.post(`/api/VoidCheck`, postdata
                        ).then(response => {
                            if (response.data > 0) {
                                toast.success("Check # " + getValues().checkNoToVoid + " voided successfully");
                            }
                            else {
                                toast.error("Error voiding check # " + getValues().checkNoToVoid + " contact your system administrator");
                            }
                            setValue('checkNoToVoid', '');
                        }).catch(error => {
                            toast.error("There was a problem voiding the check");
                            console.log(error);
                        });
                    }
                    else {
                        setValue('checkNoToVoid', '');
                        toast.warning("No payment activity was found for check #" + getValues().checkNoToVoid);
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleReassignCheck() {
        if (getValues().originalcheckNoToReassign === '' || getValues().newcheckNoToReassign === '') {
            toast.error("You must specify the original and replacement check numbers to reassign a check");
            return;
        }

        confirm({
            title: "All hours on check #" + getValues().originalcheckNoToReassign + " will be reassigned to check #" + getValues().newcheckNoToReassign,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.get(`/api/GetItemsOnCheck`, {
                    params: {
                        checkNo: getValues().originalcheckNoToReassign
                    }
                }).then(response => {
                    if (response.data > 0) {
                        let postdata = {
                            originalCheckNo: getValues().originalcheckNoToReassign,
                            replacementCheckNo: getValues().newcheckNoToReassign
                        }

                        //set selected request to 'Lost'
                        Axios.post(`/api/ReassignCheck`, postdata
                        ).then(response => {
                            if (response.data > 0) {
                                toast.success("Check number has been reassigned from " + getValues().checkNoToVoid + " to " + getValues().newcheckNoToReassign + " successfully");
                            }
                            else {
                                toast.error("Error re-assigning check # " + getValues().checkNoToVoid + " contact your system administrator");
                            }
                            setValue('originalcheckNoToReassign', '');
                            setValue('newcheckNoToReassign', '');
                        }).catch(error => {
                            toast.error("There was a problem reassigning the check");
                            console.log(error);
                        });
                    }
                    else {
                        setValue('originalcheckNoToReassign', '');
                        setValue('newcheckNoToReassign', '');
                        toast.warning("Check number " + getValues().originalcheckNoToReassign + " was not found in the database. Reassignment cancelled.");
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    function handleResetHours() {
        console.log('here 1');

        if (getValues().checkNoToReset === '') {
            console.log('here 2');

            toast.error("You must enter a check number to reset hours");
            return;
        }

        console.log('here 3');
        setState({
            ...state,
            showResetHoursSlider: true
        });

        console.log('here 4');
    }

    function handleSendCheckSummaryEmails() {
        if (getValues().checkNoToEmail === '') {
            toast.error("You must enter a check number to send summary emails");
            return;
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            CHECK UTILITIES
                        </h2>

                        {/*<button type="button" className="btn btn-submit margin-0-10" onClick={handleAddCustomer}>*/}
                        {/*    Add Contractor*/}
                        {/*</button>*/}
                    </div>
                    <Fragment>
                        <div className="row">
                            <p>&nbsp;</p>
                            <p>
                                <span className="a text-decoration-underline" id="utlVoidCheck" onClick={handleVoidCheck} style={{ cursor: 'pointer' }}>Void Check</span>&nbsp;&nbsp;Enter Check No To Void:&nbsp;<input type="text" className="inline-text-box" {...register('checkNoToVoid')} />
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span className="a text-decoration-underline" id="utlReassignCheck" onClick={handleReassignCheck} style={{ cursor: 'pointer' }}>Reassign Check</span>&nbsp;&nbsp;Original Check No:&nbsp;<input type="text" className="inline-text-box" {...register('originalcheckNoToReassign')} />&nbsp;&nbsp;New Check No:&nbsp;<input type="text" className="inline-text-box" {...register('newcheckNoToReassign')} /><br />
                                This utility transfers all hours from the original check number to the new check number specified 
                            </p>
                            <p>&nbsp;</p>
                            <p>
                                <span className="a text-decoration-underline" id="utlResetHours" onClick={handleResetHours} style={{ cursor: 'pointer' }}>Reset Hours Paid On A Check</span>&nbsp;&nbsp;Enter Check No To Void:&nbsp;<input type="text" className="inline-text-box" {...register('checkNoToReset')} />
                            </p>
                            {/*<p>&nbsp;</p>*/}
                            {/*<p>*/}
                            {/*    <span className="a text-decoration-underline" id="utlSendCheckSummaryEmails" onClick={handleSendCheckSummaryEmails} style={{ cursor: 'pointer' }}>Send Check Summary Emails</span>&nbsp;&nbsp;Enter Check No:&nbsp;<input type="text" className="inline-text-box" {...register('checkNoToEmail')} />*/}
                            {/*</p>*/}
                        </div>
                        
                        {state.showResetHoursSlider &&
                            <CheckUtilitiesResetHoursSlider checkNo={getValues().checkNoToReset} showPane={state.showResetHoursSlider} hidePane={() => setState({ ...state, showResetHoursSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}