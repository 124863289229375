import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';

export default function MRRequestHistorySlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        requestHistory: {}
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    useEffect(() => {
        var alive = true;

        if (alive && props.selectedRequestId !== null) {
            Axios.get(`/api/GetRequestHistory`, {
                params: {
                    requestID: props.selectedRequestId
                }
            }).then(response => {
                let requestHistoryList = response.data;

                console.log(requestHistoryList);

                setState({
                    ...state,
                    requestHistory: requestHistoryList,
                    dataLoaded: true,
                    showPane: true
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };

    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
        });
    }

    function handleTableChange(type, { page, sizePerPage }) {

    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.transDate)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    const columns = [{
        dataField: 'marketRecoveryHistory_ID',
        text: 'marketRecoveryHistory_ID',
        hidden: true
    }, {
        dataField: 'transDate',
        text: 'Date',
        formatter: dateFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'description',
        text: 'Description',
        sort: true
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            
        },
        onDoubleClick: (e, row, rowIndex) => {
            
        }
    };

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: 'lightgrey',
        hideSelectColumn: true,
        selected: [state.requestHistory?.marketRecoveryHistory_ID || null]
    };

    console.log(state.requestHistory.length);

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Market Recovery Request History'
            onRequestClose={hidePane}
            width={'65%'}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <Fragment>
                            {state.requestHistory.length === 0 ?
                                <div>No Market Recovery Request History Found</div>
                                :
                                <div className="fullTable">
                                    <ToolkitProvider
                                        keyField='marketRecoveryHistory_ID'
                                        data={state.requestHistory}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <BootstrapTable
                                                        pagination={paginationFactory(options)}
                                                        {...props.baseProps}
                                                        rowEvents={rowEvents}
                                                        selectRow={selectRow}
                                                        hover condensed />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}