import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast, confirm } from '@rickylandino/react-messages';
import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import NumberFormat from 'react-number-format';
import { message } from 'antd';
import { useForm, Controller } from "react-hook-form";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Select, Spin, Alert, Empty } from 'antd';

export default function JobDetailsSlider(props) {
    const [state, setState] = useState({
        showPane: props.showPane,
        requests: [],
        jobs: [],
        dataLoaded: false,
        selectedJobId: ''
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (props.showPane) {
            if (props.selectedJobId !== null && props.selectedJobId !== "") {
                Axios.get(`/api/GetJobWithAssignedRequests`, {
                    params: {
                        jobs_ID: props.selectedJobId
                    }
                }).then(response => {
                    setValue('formFields', response.data.job);
                    setValue('formFields.jobName', response.data.job.jobName.replace('\t', ''));
                    if (response.data.job.classification === "") {
                        setValue('formFields.classification', 'Select');
                    }
                    if (response.data.job.archived === 1) {
                        setValue('formFields.archived', '1');
                    }
                    else {
                        setValue('formFields.archived', '0');
                    }

                    let assignedRequests = response.data.assignedRequests

                    setState({
                        ...state,
                        selectedJobId: props.selectedJobId,
                        dataLoaded: true,
                        jobs: props.jobs,
                        requests: assignedRequests,
                        showPane: props.showPane
                    });
                }).catch(error => {
                    console.log(error);
                });
            }
            else {
                setValue('formFields', {});

                setState({
                    ...state,
                    selectedJobId: null,
                    showPane: props.showPane,
                    buttonLabel: 'Add New Job',
                    jobs: props.jobs,
                    dataLoaded: true
                }, () => console.log('hello'));
            }
        }
    }, [props]);

    function hidePane() {
        //now update the parent list
        let jobs = props.jobs;
        //const selectedIdx = 1;
        const selectedIdx = jobs.findIndex(j => j.jobs_ID === getValues().formFields.jobs_ID);
        jobs[selectedIdx] = getValues().formFields;

        //for graceful, animated close
        setTimeout(() => props.updateTableDisplay(jobs, selectedIdx), 1000);

        setState({
            ...state,
            showPane: false,
        });
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    function handleSelectRequest(e, idx) {
        const target = e.target;
        const value = target.value;

        var newList = [...state.requests];
        var itemToUpdate = newList[idx];

        itemToUpdate.selected = !itemToUpdate.selected;
        newList[idx] = itemToUpdate;

        setState({
            ...state,
            requests: newList
        });
    }

    function selectFormatter(cell, row, idx) {
        return (
            <input type="checkbox" id="chkSelect" name="chkSelect" checked={row.selected} onChange={(e) => handleSelectRequest(e, idx)} />
        );
    }

    const columns = [{
        dataField: 'mrRequest.requestID',
        text: 'Request ID',
        hidden: true
    }, {
        dataField: 'selected',
        text: 'Select',
        formatter: selectFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '130px', textAlign: 'center' }
    }, {
        dataField: 'mrRequest.requestStatus',
        text: 'Status',
        sort: true,
        headerStyle: { width: '100px', textAlign: 'center' }
    }, {
        dataField: 'contractorName',
        text: 'Contractor',
        sort: true
    }, {
        dataField: 'mrRequest.projectName',
        text: 'Project Details',
        sort: true
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {

        },
        onDoubleClick: (e, row, rowIndex) => {

        }
    };

    function handleSubmit() {
        let formFields = getValues().formFields;

        if (!formFields?.jobName) {
            toast.error("Job Name is a mandatory field");
            return;
        } else {
            if (state.selectedJobId) {
                Axios.post(`/api/UpdateJob`, formFields
                ).then(response => {
                    toast.success("Job Information Successfully Updated");

                    //now update the parent list
                    //the next line basically creates kvp with an object named memberModel 
                    let jobs = state.jobs;
                    const selectedIdx = jobs.findIndex(j => j.jobs_ID === formFields.jobs_ID);
                    jobs[selectedIdx] = formFields;
                    props.updateTableDisplay(jobs, selectedIdx);

                }).catch(error => {
                    toast.error("Problem Encountered Updating Job");

                    console.log(error);
                });
            }
            else {
                Axios.post(`/api/AddNewJob`, formFields
                ).then(response => {
                    if (response.data) {
                        toast.success("New Job Successfully Added");

                        //now update the parent list
                        let jobs = response.data.jobs;
                        const idxToDirectTo = jobs.findIndex(j => j.jobs_ID === response.data.newID);
                        props.updateTableDisplay(jobs, idxToDirectTo);
                    }

                }).catch(error => {
                    toast.error("Problem Encountered Adding New Job");

                    console.log(error);
                });
            }
        }
    }

    function handleGetZipCode() {
        Axios.get(`/api/GetZipCode`, {
            params: {
                address: getValues().formFields.address,
                city: getValues().formFields.city,
                state: getValues().formFields.state
            }
        }).then(response => {
            console.log(response.data);

            setValue('formFields.zip', response.data);

            setState({
                ...state,
            });
        }).catch(error => {
            toast.error("Problem retrieving zip+4");
            console.log(error);
        });
    }

    function handleUnionStatusChange(event) {
        const target = event.target;
        setValue('formFields.selectedUnionStatus', target.value);

        setState({ ...state });
    }

    function handleJobStatusChange(event) {
        const target = event.target;
        setValue('formFields.status', target.value);

        setState({ ...state });
    }

    function handleArchivedChange(event) {
        const target = event.target;
        setValue('formFields.archived', target.value);

        setState({ ...state });
    }

    function onSelectAll() {
        var newList = [...state.requests];
        newList.forEach((request, idx) => {
            request.selected = true;
        });

        setState({
            ...state,
            requests: newList
        });
    }

    function onDeselectAll() {
        var newList = [...state.requests];
        newList.forEach((request, idx) => {
            request.selected = false;
        });

        setState({
            ...state,
            requests: newList
        });
    }

    function onSetToLost() {
        var selectedRequestsCount = 0;

        var newList = [...state.requests];

        newList.forEach((request, idx) => {
            if (request.selected == true) {
                request.mrRequest.requestStatus = "Lost";

                let postdata = {
                    RequestID: request.mrRequest.requestID
                }

                //set selected request to 'Lost'
                Axios.post(`/api/SetRequestToLost`, postdata
                ).then(response => {
                    
                }).catch(error => {
                    toast.error("There was a problem setting requests to 'Lost'");
                    console.log(error);
                });

                selectedRequestsCount += 1;
            }
        });

        setState({
            ...state,
            requests: newList
        });

        if (selectedRequestsCount > 0) {
            toast.success("Selected requests set to 'Lost'");
        }
        else {
            toast.info("No requests selected to set to 'Lost'");
        }
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={props.selectedJobId ? 'Edit Existing Job Details' : 'Add New Job'}
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {!state.loading &&
                        <>
                            <Card className="local777Card">
                                <Card.Header><span>Job Information (*Indicates required field)</span></Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Job Name</label>
                                            <input name="jobName" className="form-control-custom" {...register('formFields.jobName')} />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <label className="form-label">Job Details</label>
                                            <input name="jobDetails" className="form-control-custom" {...register('formFields.jobDetails')} />
                                        </div>

                                        <div className="form-group col-lg-4">
                                            <label className="form-label">Address</label>
                                            <input type="text" {...register("formFields.address")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">City</label>
                                            <input type="text" {...register("formFields.city")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-1">
                                            <label className="form-label">State</label>
                                            <select className="form-control-custom" {...register("formFields.state")}>
                                                <option>Select</option>
                                                <option>CT</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Zip</label>
                                            <input type="text" {...register("formFields.zip")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-1">
                                            <label className="form-label">&nbsp;</label>
                                            <button className="btn btn-submit" onClick={handleGetZipCode}>Get Zip Code</button>
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <fragment>
                                                <label className="form-label">Union Status</label><br />
                                                <span><input type="radio" name="unionStatus" value="U" checked={getValues().formFields?.unionStatus === 'U'} onChange={handleUnionStatusChange} /></span>&nbsp;Full Union
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="unionStatus" value="N" checked={getValues().formFields?.unionStatus === 'N'} onChange={handleUnionStatusChange} /></span>&nbsp;Full Non-Union
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="unionStatus" value="M" checked={getValues().formFields?.unionStatus === 'M'} onChange={handleUnionStatusChange} /></span>&nbsp;Mixed
                                            </fragment>
                                        </div>
                                        <div className="form-group col-lg-3">
                                            <label className="form-label">Tags</label>
                                            <input type="text" {...register("formFields.tags")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Classification</label>
                                            <select className="form-control-custom" {...register("formFields.classification")}>
                                                <option>Select</option>
                                                <option>Commercial</option>
                                                <option>Educational</option>
                                                <option>Industrial</option>
                                                <option>Medical</option>
                                                <option>Residential</option>
                                                <option>Service</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-1">
                                            <label className="form-label">Zone/Region</label>
                                            <select className="form-control-custom" {...register("formFields.region")}>
                                                <option>All Zones</option>
                                                <option>Z1</option>
                                                <option>Z2</option>
                                                <option>Z3</option>
                                                <option>Z4</option>
                                            </select>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Contract Value</label>
                                            <input type="text" {...register("formFields.contractValue")} className="form-control-custom" />
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Total Value</label>
                                            <input type="text" {...register("formFields.totalValue")} className="form-control-custom" />
                                        </div>

                                        <div className="form-group col-lg-2">
                                            <fragment>
                                                <label className="form-label">Job Status</label><br />
                                                <span><input type="radio" name="status" value="A" checked={getValues().formFields?.status === 'A'} onChange={handleJobStatusChange} /></span>&nbsp;Active
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="status" value="C" checked={getValues().formFields?.status === 'C'} onChange={handleJobStatusChange} /></span>&nbsp;Completed
                                            </fragment>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <fragment>
                                                <label className="form-label">Archived</label><br />
                                                <span><input type="radio" name="archived" value="1" checked={getValues().formFields?.archived === '1'} onChange={handleArchivedChange} /></span>&nbsp;Yes
                                                <span style={{ "paddingLeft": "10px" }}><input type="radio" name="archived" value="0" checked={getValues().formFields?.archived === '0'} onChange={handleArchivedChange} /></span>&nbsp;No
                                            </fragment>
                                        </div>
                                        <div className="form-group col-lg-8">
                                            <label className="form-label">Make Job Available in Project Profiling</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input {...register("formFields.visibility")} type="checkbox" checked={getValues().formFields?.visibility === 'B'} className="custom-control-input" />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group col-lg-12">
                                            <hr style={{ border: "1px solid" }} />
                                        </div>
                                        <div className="row">
                                            <div>
                                                <label className="form-label">Market Recovery Requests for this Job:</label>&nbsp;&nbsp;
                                            </div>
                                        </div>
                                        <div className="form-group col-lg-12">
                                            {state.dataLoaded &&
                                                <Fragment>
                                                    {state.requests?.length === 0 ?
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                            <span>
                                                                No Market Recovery Requests found for this Job
                                                            </span>
                                                        } />
                                                        :
                                                        <div className="fullTable">
                                                            <ToolkitProvider
                                                                keyField='requestID'
                                                                data={state.requests}
                                                                columns={columns}
                                                                search
                                                            >
                                                                {
                                                                    props => (
                                                                        <div>
                                                                            {/*<SearchBar {...props.searchProps} />*/}
                                                                            <BootstrapTable
                                                                                pagination={paginationFactory(options)}
                                                                                {...props.baseProps}
                                                                                rowEvents={rowEvents}
                                                                                hover condensed />
                                                                        </div>
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </div>
                                                    }
                                                </Fragment>
                                            }
                                        </div>
                                        <div className="form-group col-lg-12" style={state.requests?.length === 0 ? { display: 'none' } : {}}>
                                            <span><button className="btn btn-submit btn-sm" onClick={onSelectAll}>Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <span><button className="btn btn-submit btn-sm" onClick={onDeselectAll}>De-Select All</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            <span><button className="btn btn-submit btn-sm" onClick={onSetToLost}>Set Selected Requests To 'Lost'</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                    </div>                  
                                </Card.Body>
                            </Card>
                        </>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleSubmit}>Save Changes</button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}