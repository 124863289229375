import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'antd/dist/antd.css';
import { Button } from 'antd';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { useForm, Controller } from "react-hook-form";
import Moment from 'moment';

export default function PaymentDetailRows(props) {
    function onChangeHrsToPay(e, idx) {
        const target = e.target;
        const value = target.value;

        if (target.value === '') {
            target.value = 0;
        }

        var newList = [...props.data];
        var itemToUpdate = newList[idx];

        if (itemToUpdate) {
            itemToUpdate.hrsTotal = parseFloat(value);
            newList[idx] = itemToUpdate;
        }

        props.getUpdateFromChildRow(props.rowID, newList);
    }

    function handleChangeToApprentice(e, idx) {
        var newList = [...props.data];
        var itemToUpdate = newList[idx];

        if (itemToUpdate) {
            itemToUpdate.employeeType = "A";
            newList[idx] = itemToUpdate;
        }

        props.getUpdateFromChildRow(props.rowID, newList);
    }

    function handleChangeToJourneyman(e, idx) {
        var newList = [...props.data];
        var itemToUpdate = newList[idx];

        if (itemToUpdate) {
            itemToUpdate.employeeType = "J";
            newList[idx] = itemToUpdate;
        }

        props.getUpdateFromChildRow(props.rowID, newList);
    }

    function hrsToPayFormatter(cell, row, idx) {
        //var myTotal = (row.mon + row.tue + row.wed + row.thu + row.fri + row.sat + row.sun).toString();
        console.log(props.detailRowDisabled);

        return (
            <input type="number" min="0" step="0.1" max={row.hrsTotal} className="form-control-custom" defaultValue={row.hrsTotal} onChange={(e) => onChangeHrsToPay(e, idx)} disabled={props.detailRowDisabled} />
        );
    }

    function employeeTypeFormatter(cell, row) {
        if (row.employeeType === 'J') {
            return (
                <div>Journeyman</div>
            );
        }
        else {
            return (
                <div>Apprentice</div>
            );
        }
    }

    function changeTypeFormatter(cell, row, idx) {
        if (row.employeeType === 'J') {
            return (
                <div><Button id="changeToApprentice" type="link" block onClick={(e) => handleChangeToApprentice(e, idx)}>Change to Apprentice</Button></div>
            );
        }
        else {
            return (
                <div><Button id="changeToJourneyman" type="link" block onClick={(e) => handleChangeToJourneyman(e, idx)}>Change to Journeyman</Button></div>
            );
        }
    }

    function dateFormatter(cell, row) {
        var mydate = Moment(dateWithNoTimezone(row.dateSubmitted)).format("L");
        return (
            <div>{mydate}</div>
        );
    }

    function currencyFormatter(cell, row) {
        var myValue = (row.dollarsPerHour).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return (
            <div>{myValue}</div>
        );
    }

    function hrsTotalFormatter(cell, row) {
        var myTotal = (row.mon + row.tue + row.wed + row.thu + row.fri + row.sat + row.sun).toString();
        return (
            <div><label>{myTotal}</label></div>
        );
    }

    const columns = [
        {
            dataField: "marketRecoveryHours_ID",
            text: "Product ID",
            hidden: true
        },
        {
            dataField: "employee",
            text: "Employee",
            sort: true
        },
        {
            dataField: "ssNumber",
            text: "SS #"
        },
        {
            dataField: 'hrsTotal',
            text: 'Hrs To Pay',
            formatter: hrsTotalFormatter,
            style: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' }
        },
        {
            dataField: 'dum2',
            isDummyField: true,
            text: 'Hrs To Pay',
            formatter: hrsToPayFormatter,
            style: { textAlign: 'center' },
            headerStyle: { width: '130px', textAlign: 'center' }
        },
        {
            dataField: "employeeType",
            text: "Type",
            formatter: employeeTypeFormatter,
        },
        //{
        //    dataField: 'dum3',
        //    isDummyField: true,
        //    text: '',
        //    formatter: changeTypeFormatter,
        //    style: { textAlign: 'center' },
        //    headerStyle: { width: '200px', textAlign: 'center' }
        //},
        {
            dataField: "dateSubmitted",
            text: "Date Submitted",
            formatter: dateFormatter,
            style: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' }
        },
        {
            dataField: "dollarsPerHour",
            text: "Hourly Rate",
            formatter: currencyFormatter,
            style: { textAlign: 'center' },
            headerStyle: { textAlign: 'center' }
        }
    ];

    //const rowEvents = {
    //    onClick: (e, row, rowIndex) => {
    //        console.log(e.target.id);
    //        console.log(e.target);

    //        if (e.target.id === 'chkInclude') {

    //        }
    //        else if (e.target.tagName === 'I' && e.target.id === 'editNotes') {

    //        }
    //        else if (e.target.tagName === 'I' && e.target.id === 'deleteRequest') {

    //        }
    //    },
    //    onDoubleClick: (e, row, rowIndex) => {

    //    }
    //};

    return (
        <div>
            <BootstrapTable
                bootstrap4
                keyField="marketRecoveryHours_ID"
                data={props.data}
                columns={columns}
            />
        </div>
    );
}