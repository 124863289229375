import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Fragment } from 'react';
import Globals from '../../config/globals';
import Axios from '../../config/axios';
import { Input, AutoComplete, Skeleton } from 'antd';
import { toast, confirm } from '@rickylandino/react-messages';
import { useNavigate, useLocation } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import UserDetailsSlider from './UserDetailsSlider';


export default function UserManager() {
    const { SearchBar } = Search;

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        users: [],
        selectedUser: null,
        dataLoaded: false,
        formFields: {},
        noUsers: true,
        selectedIdx: null,
        showUserDetailsSlider: false,
        contractorID: null,
        contractorName: ''
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    useEffect(() => {
        if (location.state !== null && location.state?.pageNumber !== null) {
            setTableControl({
                ...tableControl,
                page: location.state.pageNumber
            });
        }

        GetUsers();
    }, []);

    function GetUsers() {
        Axios.get(`/api/GetAllUsers`, {
            params: {
                
            }
        }).then(response => {
            let usersList = response.data;
            console.log(usersList);
            setState({
                ...state,
                users: usersList,
                //selectedContractor: response.data.length > 0 && response.data[0],
                dataLoaded: true,
                results: usersList.slice(0, 10),
                showUserDetailsSlider: false
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            if (e.target.tagName === 'I' && e.target.id === 'editUser') {
                LoadUserDetails(row.users_ID, rowIndex)
            }
            else if (e.target.tagName === 'I' && e.target.id === 'deleteUser') {
                handleDelete(row);
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            LoadUserDetails(row.users_ID, rowIndex);
        }
    };

    function LoadUserDetails(users_ID, rowIndex) {
        Axios.get(`/api/GetUserById`, {
            params: {
                userid: users_ID
            }
        }).then(response => {
            setState({
                ...state,
                selectedRequestIdx: rowIndex,
                selectedUser: response.data,
                selectedUserId: users_ID,
                showUserDetailsSlider: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleDelete(user) {
        confirm({
            title: "You are about to permanently delete this User",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"]
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                Axios.post(`/api/DeleteUser`, user
                ).then(response => {
                    if (response.data) {
                        toast.success("User has been deleted");

                        setState({
                            ...state,
                            users: response.data,
                            dataLoaded: true
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function updateUsers()
    {
        GetUsers();

        //await setState({ ...state, dataLoaded: false });

        //if (idxToDirectTo !== null && idxToDirectTo !== 0) {
        //    const page = Math.ceil(idxToDirectTo / tableControl.sizePerPage);

        //    console.log(page);

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}
    }

    function handleAddUser()
    {
        setState({
            ...state,
            selectedUserId: null,
            showUserDetailsSlider: true,
            selectedIdx: null,
            loading: false
        });
    }

    function handleTableChange(type, { page, sizePerPage }) {

    }

    async function updateTableDisplay(requests, idxToDirectTo = null) {
        //await setState({ ...state, dataLoaded: false });

        //console.log(requests[idxToDirectTo]);

        //if (idxToDirectTo !== null) {
        //    const page = idxToDirectTo > 0 ? Math.ceil(idxToDirectTo / tableControl.sizePerPage) : 1;

        //    await setTableControl({ ...tableControl, page });
        //} else {
        //    idxToDirectTo = 0
        //}

        //setState({
        //    ...state,
        //    requests,
        //    selectedRequest: requests[idxToDirectTo],
        //    dataLoaded: true,
        //    showRequestDetailsSlider: false
        //});
    }

    const pagination = paginationFactory({
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        showTotal: false,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {

            setTableControl({
                ...tableControl,
                page
            });
        },
        onSizePerPageChange: function (page, sizePerPage) {

        }
    });

    function deleteFormatter(cell, row) {
        return (
            <i id="deleteUser" className="far fa-trash-alt fa-1x ml-3 hover text-danger"></i>
        );
    }

    function openFormatter(cell, row) {
        return (
            <i id="editUser" className="fas fa-edit fa-1x ml-3 text-center hover"></i>
        );
    }

    const columns = [{
        dataField: 'users_ID',
        text: 'Users_ID',
        hidden: true
    }, {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true
    }, {
        dataField: 'firstName',
        text: 'First Name',
        sort: true
    }, {
        dataField: 'title',
        text: 'Title',
        sort: true
    }, {
        dataField: 'dum1',
        isDummyField: true,
        text: 'Manage User',
        formatter: openFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '150px' }
    }, {
        dataField: 'dum2',
        isDummyField: true,
        text: 'Delete User',
        formatter: deleteFormatter,
        style: { textAlign: 'center' },
        headerStyle: { width: '150px' }
    }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: 'lightgrey',
        hideSelectColumn: true,
        /*selected: [state.selectedContractor?.contractors_ID]*/
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="panel-hdr">
                        <h2>
                            MANAGE USERS
                        </h2>

                        <button type="button" className="btn btn-submit margin-0-10" onClick={handleAddUser}>
                            Add User
                        </button>
                    </div>
                    <Fragment>
                        <br />
                        <div className="frame-wrap">
                            {state.loading ?
                                <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                                :
                                <div className="fullTable">
                                    <ToolkitProvider
                                        keyField='users_ID'
                                        data={state.users}
                                        columns={columns}
                                    >
                                        {props => (
                                            <div>
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    striped
                                                    hover
                                                    condensed
                                                    pagination={pagination}
                                                    rowEvents={rowEvents}
                                                    selectRow={selectRow}
                                                />
                                            </div>
                                        )}
                                    </ToolkitProvider>
                                </div>
                            }
                        </div>
                        {state.showUserDetailsSlider &&
                            <UserDetailsSlider selectedUserId={state.selectedUserId} showPane={state.showUserDetailsSlider} contractors={state.users} updateUsers={updateUsers} hidePane={() => setState({ ...state, showUserDetailsSlider: false })} />
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
}