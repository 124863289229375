import React, { useState, useEffect } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { useForm, Controller } from "react-hook-form";
import Axios from '../../config/axios';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import { toast, confirm } from '@rickylandino/react-messages';

export default function CheckUtilitiesResetHoursSlider(props) {
    const { register, getValues, setValue, control, watch } = useForm();

    const [state, setState] = useState({
        showPane: props.showPane,
        hoursPaidOnCheck: {},
        selectedEntries: []
    });

    const [tableControl, setTableControl] = useState({
        page: 1,
        sizePerPage: 10
    });

    useEffect(() => {
        var alive = true;

        if (alive && props.checkNo !== null) {
            Axios.get(`/api/GetAllHoursPaidOnCheck`, {
                params: {
                    checkNo: props.checkNo
                }
            }).then(response => {
                let hoursPaidOnCheck = response.data;

                console.log(hoursPaidOnCheck);

                setState({
                    ...state,
                    hoursPaidOnCheck,
                    dataLoaded: true,
                    showPane: true,
                    selectedEntries: []
                });
            }).catch(error => {
                console.log(error);
            });
        }
        return () => {
            alive = false;
        };

    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
        });
    }

    function WeekEndingFormatter(cell, row) {
        var mydate = row.weekEnding;
        var myFormattedDate = mydate.substring(4, 6) + '/' + mydate.substring(6) + '/' + mydate.substring(0, 4);

        //20230117
        return (
            <div>{myFormattedDate}</div>
        );
    }

    const columns = [{
        dataField: 'marketRecoveryHours_ID',
        text: 'marketRecoveryHours_ID',
        hidden: true
    }, {
        dataField: 'weekEnding',
        text: 'Week Ending',
        formatter: WeekEndingFormatter,
        sort: true,
        headerStyle: { width: '120px', textAlign: 'center' }
    }, {
        dataField: 'projectName',
        text: 'Job',
        sort: true
    }, {
        dataField: 'contractorName',
        text: 'Contractor',
        sort: true
    }, {
        dataField: 'employee',
        text: 'Employee',
        sort: true
    }, {
        dataField: 'hoursRequested',
        text: 'Hours Requested',
        sort: true
    }, {
        dataField: 'actualHoursPaid',
        text: 'Hours Paid',
        sort: true
    }];

    const rowEvents = {
        onClick: (e, row, rowIndex) => {

        },
        onDoubleClick: (e, row, rowIndex) => {

        }
    };

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => { setTableControl({ ...tableControl, sizePerPage: option.page }); onSizePerPageChange(option.page) }}
                        className={`btn ${currSizePerPage === `${option.page}` ? 'btn-submit' : 'btn-secondary'}`}
                    >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

    const options = {
        page: tableControl.page,
        sizePerPage: tableControl.sizePerPage,
        sizePerPageRenderer,
        showTotal: true,
        sizePerPageList: [10, 25, 50, 100],
        onPageChange: function (page, sizePerPage) {
            setTableControl({
                ...tableControl,
                page
            });
        }
    };

    //const selectRow = {
    //    mode: 'checkbox',
    //    clickToSelect: false,
    //    selected: state.selected,
    //    onSelect: handleOnSelect,
    //    onSelectAll: handleOnSelectAll
    //};

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
            <input
                type="checkbox"
                ref={(input) => {
                    if (input) input.indeterminate = indeterminate;
                }}
                {...rest}
            />
        ),
        selectionRenderer: ({ mode, ...rest }) => (
            <input type={mode} {...rest} />
        ),
        onSelect: (row, isSelect, rowIndex, e) => {
            let mySelectedEntrieList = state.selectedEntries;

            if (isSelect) {
                mySelectedEntrieList.push(row);
            }
            else {
                const index = mySelectedEntrieList.indexOf(row);
                if (index > -1) {
                    mySelectedEntrieList.splice(index, 1);
                }
            }

            setState({
                ...state,
                selectedEntries: mySelectedEntrieList
            });
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {

                //set selected exhibitors to entire list
                setState({
                    ...state,
                    selectedEntries: state.hoursPaidOnCheck
                });

                return state.hoursPaidOnCheck.map(row => row.marketRecoveryHours_ID);
            } else {
                setState({
                    ...state,
                    selectedEntries: []
                });

                return [];
            }
        }
    };

    function handleResetHours() {
        if (state.selectedEntries.length === 0) {
            toast.error("No Items Are Selected To Reset");
            return;
        }

        confirm({
            title: "All selected hours will be reset",
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                let mySelectedEntries = state.selectedEntries;

                let postdata = mySelectedEntries
           
                //set selected request to 'Lost'
                Axios.post(`/api/ResetHoursOnCheck`, postdata
                ).then(response => {
                    if (response.data > 0) {
                        toast.success("Selected hours on check # " + props.checkNo + " reset successfully");
                    }
                    else {
                        toast.error("Error resetting hours check # " + props.checkNo + " contact your system administrator");
                    }

                    setState({
                        ...state,
                        showPane: false
                    });

                }).catch(error => {
                    toast.error("There was a problem resetting hours");
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title='Reset Hours For Check'
            onRequestClose={hidePane}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <Fragment>
                            {state.hoursPaidOnCheck.length === 0 ?
                                <div>No Market Recovery Request History Found</div>
                                :
                                <div className="fullTable">
                                    <ToolkitProvider
                                        keyField='marketRecoveryHours_ID'
                                        data={state.hoursPaidOnCheck}
                                        columns={columns}
                                        search
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <BootstrapTable
                                                        pagination={paginationFactory(options)}
                                                        {...props.baseProps}
                                                        rowEvents={rowEvents}
                                                        selectRow={selectRow}
                                                        hover condensed />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 pt-4">
                    <button className="btn btn-submit margin-left-15 float-left" onClick={handleResetHours}>Reset Hours</button>&nbsp;&nbsp;
                    {state.selectedEntries.length} Items Selected
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>
        </SlidingPane>
    );
}